import { useState } from 'react';
import { Tabs } from 'antd';
import ConditionalPriority from '../ConditionalPriority';
import EditAgencySettings from '../EditAgencySettings';
import './style.css';

const { TabPane } = Tabs;

const AgencyFeatureTab = ({
  editFeaturePersistanceTSPResponse,
  editFeaturePersistanceTSP,
  occupancyData,
}) => {
  const [activeTab, setactiveTab] = useState('analytics');

  return (
    <Tabs
      onChange={setactiveTab}
      defaultActiveKey={activeTab}
      className={'activity-tabs'}
    >
      <TabPane tab="Analytics" key="analytics">
        <EditAgencySettings
          editFeaturePersistanceTSP={editFeaturePersistanceTSP}
          createFPResponse={editFeaturePersistanceTSPResponse}
        />
      </TabPane>
      <TabPane tab="Conditional Priority" key="conditionalPriority">
        <ConditionalPriority
          occupancyData={occupancyData}
        ></ConditionalPriority>
      </TabPane>
    </Tabs>
  );
};
export default AgencyFeatureTab;
