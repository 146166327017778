// Definitions
export const TooltipText = {
  DateRange:
    'Selectable dates correspond to available data for the selected timeperiod. The date range can span a maximum of 90 days',
  IhmTotal: 'Total number of phase selectors in system',
  IhmNormal:
    "A phase selector and intersection is considered 'Normal' if it is online and sending a heartbeat.",
  IhmWarning:
    "A phase selector and intersection is considered in 'Warning' if it has the status of Initializing, Standby or Boot Mode",
  IhmError:
    "A phase selector and intersection is considered in 'Error' if it has the status of Broken, Diagnostic or is Offline",
};
