import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import Table, { alphanumericSorter } from '../../../../common/components/Table';
import { capitalizeFirstLetter } from '../../utils';
import MakeModel from '../MakeModel';
import './style.css';

const AssociatedDevicesTable = ({
  devices,
  onEdit,
  onDissociate,
  onDelete,
}) => {
  // Disable possible multiple dissociate requests
  const [dissociatedDeviceIds, setDissociatedDeviceIds] = useState([]);

  useEffect(() => {
    setDissociatedDeviceIds([]);
  }, [devices]);

  const handleDissociate = useCallback(
    (device) => {
      setDissociatedDeviceIds((prev) => [...prev, device.id]);
      onDissociate(device);
    },
    [onDissociate]
  );

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Device ID',
      width: 140,
      render: (_, record) => <a onClick={() => onEdit(record)}>{record.id}</a>,
      sorter: alphanumericSorter('id'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      width: 180,
      sorter: alphanumericSorter('description'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'gttSerial',
      dataIndex: 'gttSerial',
      title: 'GTT Serial',
      width: 150,
      sorter: alphanumericSorter('gttSerial'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'serial',
      dataIndex: 'serial',
      title: 'Device Serial',
      width: 140,
      sorter: alphanumericSorter('serial'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'deviceMakeModel',
      dataIndex: 'make',
      title: 'Make & Model',
      width: 200,
      render: (_, { make, model }) => <MakeModel makeModel={[make, model]} />,
      sorter: alphanumericSorter('make'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'addressMac',
      dataIndex: 'addressMac',
      title: 'MAC',
      width: 140,
      sorter: alphanumericSorter('addressMac'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'addressLan',
      dataIndex: 'addressLan',
      title: 'LAN',
      width: 140,
      sorter: alphanumericSorter('addressLan'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'addressWan',
      dataIndex: 'addressWan',
      title: 'WAN',
      width: 140,
      sorter: alphanumericSorter('addressWan'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'imei',
      dataIndex: 'imei',
      title: 'IMEI',
      width: 140,
      sorter: alphanumericSorter('imei'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'devcertId',
      dataIndex: 'devcertId',
      title: 'Dev Cert ID',
      width: 200,
      sorter: alphanumericSorter('devcertId'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 199,
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a
            disabled={dissociatedDeviceIds.includes(record.id)}
            style={{ color: '#faad14' }}
            onClick={() => handleDissociate(record)}
          >
            Dissociate
          </a>
          <a style={{ color: 'red' }} onClick={() => onDelete(record)}>
            Delete
          </a>
        </Space>
      ),
      sorter: alphanumericSorter('actions'),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <Table
      style={{ width: '100%' }}
      className="tabs-table-associated-devices"
      columns={columns}
      dataSource={devices}
      pagination={false}
      bordered={false}
      scroll={{ x: 1140 }}
    />
  );
};

const VehiclesTable = ({
  vehicles,
  isLoading,
  onEdit,
  onDelete,
  onChangePreemption,
  onDissociateDevice,
  onDeleteDevice,
  onEditDevice,
}) => {
  const { agency, preemptionStatuses } = useSelector(
    ({ configuration }) => configuration
  );

  // Handle dissociating a device from a vehicle
  const handleDissociateDevice = useCallback(
    (device) => {
      onDissociateDevice(device);

      if (
        !('preemptionLicense' in device) ||
        device.preemptionLicense === 'inactive'
      ) {
        return;
      }

      onChangePreemption({
        agencyId: agency.agencyId,
        devices: {
          [device.id.toString()]: 'inactive',
        },
      });
    },
    [agency.agencyId, onChangePreemption, onDissociateDevice]
  );

  const vehiclesColumns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Vehicle Name',
      render: (_, record) => (
        <a onClick={() => onEdit(record)}>{record.name}</a>
      ),
      sorter: alphanumericSorter('name'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      sorter: alphanumericSorter('description'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Type',
      sorter: alphanumericSorter('type'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'priority',
      dataIndex: 'priority',
      title: 'Priority',
      sorter: alphanumericSorter('priority'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'makeModel',
      dataIndex: 'makeModel',
      title: 'Device(s)',
      render: (_, { makeModels }) => <MakeModel makeModel={makeModels} />,
    },
    {
      key: 'preemption',
      dataIndex: 'preemptionLicense',
      title: 'Pre-emption',
      render: (_, { id, preemption }) => (
        <p>
          {capitalizeFirstLetter(
            preemptionStatuses[id] ? preemptionStatuses[id] : preemption || ''
          )}
        </p>
      ),
    },
    {
      key: 'vid',
      dataIndex: 'vid',
      title: 'VID',
      sorter: alphanumericSorter('vid'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'class',
      dataIndex: 'class',
      title: 'Class',
      sorter: alphanumericSorter('class'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: 'Actions',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <a style={{ color: '#1890ff' }} onClick={() => onEdit(record)}>
            Edit
          </a>
          <a style={{ color: 'red' }} onClick={() => onDelete(record)}>
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        className="tabs-table-vehicles"
        isLoading={isLoading}
        columns={vehiclesColumns}
        expandable={{
          expandedRowRender: (record) => (
            <AssociatedDevicesTable
              devices={record.devices}
              onDissociate={handleDissociateDevice}
              onDelete={onDeleteDevice}
              onEdit={onEditDevice}
            />
          ),
          rowExpandable: (record) => record.devices?.length,
        }}
        dataSource={vehicles}
        pagination={true}
        bordered={false}
        scroll={{ x: 1140 }}
        rowKey="id"
      />
    </>
  );
};

export default VehiclesTable;
