import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dateDiffInDays } from '../../../common/utils/dateDiff';
import { useGetVehiclesQuery } from '../../configuration/api';
import { useGetRTRadioMessagesQuery } from '../api';

const useVehicles = () => {
  const { agencyId } = useSelector(({ user }) => user);

  const {
    data: vehicles,
    isLoading: vehiclesIsLoading,
    isError: vehiclesIsError,
    error: vehiclesError,
  } = useGetVehiclesQuery({ agencyId });

  const {
    data: rtRadioMsgs,
    isLoading: rtRadioMsgsIsLoading,
    isError: rtRadioMsgsIsError,
    error: rtRadioMsgsError,
  } = useGetRTRadioMessagesQuery();

  // Filter out unnecessary info from vehicles data
  const vehiclesData = useMemo(
    () =>
      vehicles?.map((vehicle) => ({
        name: vehicle?.name,
        vid: vehicle?.vid,
      })),
    [vehicles]
  );

  // Combine vehicles data with corresponding RTRadio message
  const {
    healthData: vehicleHealthData,
    numVehiclesInComm: numVehiclesInCommunication,
  } = useMemo(() => {
    if (
      !vehiclesData ||
      !rtRadioMsgs ||
      vehiclesData?.length === 0 ||
      Object.entries(rtRadioMsgs)?.length === 0
    )
      return { healthData: [], numVehiclesInComm: 0 };

    let numVehiclesInComm = 0;
    const today = new Date();
    const healthData = [];

    vehiclesData?.forEach((vehicle) => {
      const rtRadioMsg = rtRadioMsgs[vehicle.vid];
      if (!rtRadioMsg) return;

      const lastHeardDate = new Date(rtRadioMsg.iotTimestamp.slice(0, 10));
      const daysSinceLastHeard = dateDiffInDays(lastHeardDate, today);

      healthData.push({
        ...vehicle,
        ...rtRadioMsg,
        daysSinceLastHeard,
      });
      numVehiclesInComm += daysSinceLastHeard < 1 ? 1 : 0;
    });
    return { healthData, numVehiclesInComm };
  }, [rtRadioMsgs, vehiclesData]);

  const vehicleHealthDataIsLoading = useMemo(
    () =>
      vehiclesIsLoading ||
      rtRadioMsgsIsLoading ||
      (vehicles &&
        vehicles?.length !== 0 &&
        rtRadioMsgs &&
        Object.entries(rtRadioMsgs)?.length !== 0 &&
        vehicleHealthData.length === 0),
    [
      vehiclesIsLoading,
      rtRadioMsgsIsLoading,
      vehicles,
      rtRadioMsgs,
      vehicleHealthData,
    ]
  );

  const error = useMemo(() => {
    if (vehiclesIsError) return vehiclesError;
    if (rtRadioMsgsIsError) return rtRadioMsgsError;
    return null;
  }, [vehiclesIsError, rtRadioMsgsIsError, vehiclesError, rtRadioMsgsError]);

  return {
    numVehiclesInSystem: vehiclesData?.length || 0,
    numVehiclesInCommunication,
    vehicleHealthData,
    isLoading: vehicleHealthDataIsLoading,
    isError: vehiclesError || rtRadioMsgsError,
    error,
  };
};

export default useVehicles;
