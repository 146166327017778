import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import {
  ADMIN_LANDING_PAGE,
  AGENCY_LANDING_PAGE,
} from '../../../../common/constants';
import { UserType } from '../../../../common/enums';

const LoginCallback = () => {
  const { username, admin, userType, error, agency, region } = useSelector(
    ({ user }) => user
  );

  if (username && (admin || userType === UserType.AgencyAdmin))
    return <Redirect to={ADMIN_LANDING_PAGE} />;
  if (username && userType === UserType.AgencyViewer)
    return <Redirect to={AGENCY_LANDING_PAGE} />;
  if (username && !agency) {
    return <Redirect to="/404" />;
  }
  if (error) return <Redirect to="/400" />;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: '#0f9647',
        flexDirection: 'column',
        padding: '1em',
      }}
    >
      <Spin size="large" />
      <h2 style={{ color: '#1890ff' }}>Loading...</h2>
    </div>
  );
};

export default LoginCallback;
