import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import useAgency from '../../../configuration/hooks/useAgency';
import {
  loginUserSuccess,
  logoutUserSuccess,
  authUserFailure,
  setAdminAgency,
} from '../../store/slice';
import openNotification from '../../../../common/components/notification';
import { accessLocalStorage } from '../../../../common/utils';
import {
  AUTH_FAILURE_REFRESH,
  AUTH_FAILURE_SIGNIN,
  AUTH_FAILURE_NOAGENCY,
} from '../../constants';
import { UserType } from '../../../../common/enums';
import { IS_MIO_APP } from '../../../../common/constants/environment';

const { storeLocalItem, getLocalItem } = accessLocalStorage();

const redirectUserToCognitoSignIn = async () => {
  await Auth.signOut({ opts: { global: true } });
  Auth.federatedSignIn();
};

const formatSuccessPayload = (payload) => {
  const isAdmin =
    payload['custom:user_type']?.length &&
    payload['custom:user_type'] === UserType.PowerUser;

  return {
    username: payload['cognito:username'],
    admin: isAdmin,
    userType: payload['custom:user_type'],
    agencyId: payload['custom:agencyGUID'],
  };
};

const CognitoAuthListener = ({ children }) => {
  const { agencyId } = useSelector(({ user }) => user);

  // load agency data
  const { agency: agencyData, isLoading: isAgencyLoading } = useAgency({
    id: agencyId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!agencyData || IS_MIO_APP) return;
    setAdminAgency(agencyData);
  }, [agencyData, agencyId]);

  useEffect(() => {
    let tokenPayload;

    // From https://docs.amplify.aws/lib/auth/social/q/platform/js/#setup-frontend
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      try {
        switch (event) {
          case 'cognitoHostedUI':
            tokenPayload = data.signInUserSession.idToken.payload;
            if (
              !tokenPayload['custom:agencyGUID'] &&
              tokenPayload['custom:user_type'] !== 'power_user'
            ) {
              dispatch(authUserFailure(event));
              throw AUTH_FAILURE_NOAGENCY;
            }
            dispatch(loginUserSuccess(formatSuccessPayload(tokenPayload)));
            break;
          case 'tokenRefresh':
            // Amplify now handles this internally
            break;
          case 'signOut':
            dispatch(logoutUserSuccess());
            break;
          case 'oAuthSignOut':
            dispatch(logoutUserSuccess());
            break;
          case 'signIn_failure':
            throw AUTH_FAILURE_SIGNIN;
          case 'cognitoHostedUI_failure':
            throw AUTH_FAILURE_SIGNIN;
          case 'tokenRefresh_failure':
            throw AUTH_FAILURE_REFRESH;
          default:
        }
      } catch (error) {
        // The error needs to be stored in local stoage here in order to keep track of the last error
        // Storing the error only in the Redux store does not always work because some of the above cases,
        // such as 'tokenRefresh_failure', cause a refresh and wipe out the Redux store
        const userError = getLocalItem('authError');
        if (event !== userError)
          openNotification({
            message: !error?.message ? 'Authentication Error' : error?.message,
            description: !error?.description ? `${error}` : error?.description,
          });
        dispatch(authUserFailure(event));
        storeLocalItem('authError', event);
        redirectUserToCognitoSignIn();
      }
    });

    const checkExistingUserData = async () => {
      try {
        const existingLoggedInUserInfo = await Auth.currentAuthenticatedUser();
        if (existingLoggedInUserInfo) {
          tokenPayload =
            existingLoggedInUserInfo.signInUserSession.idToken.payload;

          if (
            !tokenPayload['custom:agencyGUID'] &&
            tokenPayload['custom:user_type'] !== 'power_user'
          ) {
            dispatch(authUserFailure('NoAgency_failure'));
            throw AUTH_FAILURE_NOAGENCY;
          }

          dispatch(loginUserSuccess(formatSuccessPayload(tokenPayload)));
        }
      } catch (error) {
        if (error != null) {
          dispatch(authUserFailure(error?.message ?? error));
        }
      }
    };

    checkExistingUserData();
  }, [agencyData, dispatch, isAgencyLoading]);

  return children;
};

export default CognitoAuthListener;
