import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AgencyGeneralTab from '../../components/AgencyGeneralTab';
import Breadcrumb from '../../../../common/components/Breadcrumb';
import Card from '../../../../common/components/Card';
import ConfigurationLayout from '../../../../common/layouts/ConfigurationLayout';
import openNotification from '../../../../common/components/notification';
import useAgency from '../../hooks/useAgency';
import { useTspAnalyticsFeature } from '../../../../common/hooks/useAgencyDefaults';
import './style.css';

const AgencyPage = () => {
  const { agencyId, admin } = useSelector(({ user }) => user);
  const { agency } = useAgency();
  const regionId = agency?.regionId;

  const {
    agency: agencyData,
    isError: isAgencyError,
    occupancyData,
    error: agencyError,
    editAgencyResponse,
    edit: editAgency,
  } = useAgency();

  const {
    isLoading: isAgencyInfoloading,
    editFeaturePersistanceTSPResponse,
    editFeaturePersistanceTSP,
  } = useTspAnalyticsFeature();

  useEffect(() => {
    if (isAgencyError)
      openNotification({
        message: 'Error Getting Agency Data',
        description: agencyError?.message,
      });
  }, [agencyError?.message, isAgencyError]);

  return (
    <ConfigurationLayout above={<Breadcrumb />}>
      <Card className="custom-card" loading={isAgencyInfoloading}>
        <AgencyGeneralTab
          agencyId={agencyId}
          agencyData={agencyData}
          editAgency={editAgency}
          editAgencyResponse={editAgencyResponse}
          admin={admin}
          regionId={regionId}
          editFeaturePersistanceTSPResponse={editFeaturePersistanceTSPResponse}
          editFeaturePersistanceTSP={editFeaturePersistanceTSP}
          occupancyData={occupancyData}
        />
      </Card>
    </ConfigurationLayout>
  );
};

export default AgencyPage;
