export const Timeframe = {
  PeakAM: 'peak_am',
  PeakPM: 'peak_pm',
  OffPeak: 'offpeak',
  Weekends: 'weekend',
  All: 'all',
};

export const Direction = {
  Inbound: 'inbound',
  Outbound: 'outbound',
  All: 'all',
};

export const Period = {
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Year: 'year',
};

export const Compare = {
  Previous: 'previous',
};

export const SortBy = {
  MostRecent: 'most-recent',
  Earliest: 'earliest',
};

export const PEAKS_DEFIINITION = [
  {
    end_time: '09:00:00',
    label: 'peak_am',
    start_time: '06:00:00',
  },
  {
    end_time: '19:00:00',
    label: 'peak_pm',
    start_time: '16:00:00',
  },
];

export const ON_TIME_RANGE = [-5, 2];

export const TimeZone = {
  Central: 'Central',
  Mountain: 'Mountain',
  Eastern: 'Eastern',
  Pacific: 'Pacific',
  Arizona: 'Arizona',
  Newfoundland: 'Newfoundland',
  Atlantic: 'Atlantic',
  Saskatchewan: 'Saskatchewan',
};

export const Metric = {
  ScheduleDeviation: 'scheduleDeviation',
  OnTimePercentage: 'onTimePercentage',
  SignalDelay: 'signaldelay',
  TravelTime: 'traveltime',
  DwellTime: 'dwelltime',
  DriveTime: 'drivetime',
};

export const Make = {
  GTT: 'GTT',
  SierraWireless: 'Sierra Wireless',
  Cradlepoint: 'Cradlepoint',
  Whelen: 'Whelen',
  Teletrac: 'Teletrac',
};

export const Model = {
  GTT2100: '2100',
  GTT2101: '2101',
  GTT2150: '2150',
  GTT2151: '2151',
  MP70: 'MP-70',
  VSG: 'VSG',
  IBR900: 'IBR900',
  IBR1700: 'IBR1700',
  R1900: 'R1900',
  Integration: 'Integration',
};

export const MakeModels = {
  [Make.GTT]: [
    Model.GTT2100,
    Model.GTT2101,
    Model.GTT2150,
    Model.GTT2151,
    Model.Integration,
  ],
  [Make.SierraWireless]: [Model.MP70],
  [Make.Cradlepoint]: [Model.IBR1700, Model.R1900, Model.IBR900],
  [Make.Whelen]: [Model.Integration],
  [Make.Teletrac]: [Model.Integration],
};

export const UserType = {
  PowerUser: 'power_user',
  AgencyAdmin: 'agency_admin',
  AgencyViewer: 'agency_viewer',
};

export const Integration = {
  Whelen: 'Whelen',
};
