import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Input, Form, Col, Row, Switch } from 'antd';
import Button from '../../../../common/components/Button';
import EditThresholdsModal from '../EditThresholdsModal';
import { mapDataToInitialValues } from '../../utils';
import { CHANNEL_TYPES } from '../../constants';
import { numericValidator } from '../../../../common/utils/validations';
import {
  setIntersectionThresholds,
  setIntersectionSaved,
} from '../../store/slice';
import './style.css';

const { TabPane } = Tabs;

const ThresholdsForm = ({
  formData,
  channelNames,
  onFormDataChange,
  priority,
  resetToDefaults,
}) => {
  const [allChannelsData, setAllChannelsData] = useState([]);
  const [allChannelsClasses, setAllChannelsClasses] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    const channelsData = [];
    const channelsClasses = {};

    formData?.forEach((data, index) => {
      const { classes, ...channel } = data;
      channelsData[index] = channel;
      channelsClasses[index] = classes;
    });

    const initialValues = mapDataToInitialValues(channelsData);
    form.setFieldsValue(initialValues);
    setAllChannelsData(channelsData);
    setAllChannelsClasses(channelsClasses);
  }, [form, formData]);

  const handleFormDataChange = useCallback(
    (channelsClasses) => {
      const formValues = form.getFieldsValue();
      const channelsData = [...allChannelsData];

      // Form has maxCallTime and lostSignalHold for each channel, but the
      // data needs to be formatted in the manner expected by the API
      Object.entries(formValues)?.forEach((entry) => {
        const [key, value] = entry;
        const [index, output] = key.split('-');
        if (!(index in channelsData)) channelsData[index] = {};
        channelsData[index][output] = value;
      });

      channelsData?.forEach((channel, index) => {
        channel.classes = channelsClasses
          ? channelsClasses[index]
          : allChannelsClasses[index];
      });

      onFormDataChange(channelsData);
    },
    [form, allChannelsData, allChannelsClasses, onFormDataChange]
  );

  const applyToAll = useCallback(() => {
    const formValues = form.getFieldsValue();

    // Load channel 'A' settings
    const lostSignalHold = `${formValues['0-lostSignalHold']}`;
    const maxCallTime = `${formValues['0-maxCallTime']}`;
    const preemptEnabled = formValues['0-preemptEnabled'];
    if (lostSignalHold === undefined || maxCallTime === undefined) return;
    const channelClasses = allChannelsClasses[0];

    const updatedAllChannelsData = allChannelsData?.map((data, _) => {
      const channel = { ...data };
      channel.maxCallTime = maxCallTime;
      channel.lostSignalHold = lostSignalHold;
      channel.classes = channelClasses;
      channel.preemptEnabled = preemptEnabled;
      return channel;
    });

    onFormDataChange(updatedAllChannelsData);
  }, [allChannelsClasses, allChannelsData, form, onFormDataChange]);

  const handleClick = (index) => {
    setCurrentChannel(index);
    setEditing(true);
  };

  return (
    <>
      <Form
        form={form}
        className="thresholds-form"
        layout="vertical"
        onFieldsChange={() => handleFormDataChange()}
      >
        <Row>
          {formData.map((_, index) => (
            <Col key={index} span={6}>
              <p className="thresholds-form-channel-title">
                Channel {CHANNEL_TYPES[index]}
                {` `}
                {channelNames[index] !== '' && `(${channelNames[index]})`}
              </p>
            </Col>
          ))}
        </Row>
        <Row>
          {formData.map((_, index) => (
            <Col key={index} span={6}>
              <Form.Item
                className="priority-switch"
                name={`${index}-preemptEnabled`}
                label={<p>Priority Enabled</p>}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row>
          {formData.map((_, index) => (
            <Col key={index} span={6}>
              <Form.Item
                className="thresholds-form-item"
                name={`${index}-maxCallTime`}
                label="Max Call Times"
                rules={[
                  numericValidator({
                    min: 0,
                    max: 65535,
                    errorMessage:
                      'Max Call Times should be within 0 - 65535 seconds',
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="thresholds-form-item"
                name={`${index}-lostSignalHold`}
                label="Lost Signal Hold"
                rules={[
                  numericValidator({
                    min: 0,
                    max: 65535,
                    errorMessage:
                      'Lost Signal Hold should be within 0 - 65535 seconds',
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="thresholds-form-item"
                label="ETA & Distance"
              >
                <Button
                  className="thresholds-button-edit"
                  onClick={() => handleClick(index)}
                >
                  Edit Thresholds
                </Button>
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={15}>
            <div className="thresholds-buttons">
              <Button
                className="thresholds-button-apply"
                type="secondary"
                size="lg"
                onClick={() => applyToAll()}
              >
                Apply Channel {`<A>`} settings to all Channels (?)
              </Button>
              <Button type="danger" size="lg" onClick={() => resetToDefaults()}>
                Reset to Default
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <EditThresholdsModal
        showModal={editing}
        setShowModal={setEditing}
        channel={currentChannel}
        allChannelsClasses={allChannelsClasses}
        onAllChannelsClassesChange={(channels) =>
          handleFormDataChange(channels)
        }
        priorityLevel={priority}
      />
    </>
  );
};

const ApproachesThresholds = ({
  thresholds,
  channelNames,
  resetToDefaults,
}) => {
  const { lowPriority, highPriority } = thresholds;
  const dispatch = useDispatch();

  const handleLowPriorityThresholdsChange = (t) => {
    dispatch(setIntersectionThresholds({ ...thresholds, lowPriority: t }));
    dispatch(setIntersectionSaved(false));
  };
  const handleHighPriorityThresholdsChange = (t) => {
    dispatch(setIntersectionThresholds({ ...thresholds, highPriority: t }));
    dispatch(setIntersectionSaved(false));
  };

  return (
    <Tabs
      className="thresholds-tabs"
      tabBarStyle={{ backgroundColor: '#f1efef' }}
    >
      <TabPane tab="Low Priority" key="2.1">
        <ThresholdsForm
          formData={lowPriority}
          channelNames={channelNames}
          onFormDataChange={handleLowPriorityThresholdsChange}
          priority={'Low'}
          resetToDefaults={resetToDefaults}
        />
      </TabPane>
      <TabPane tab="High Priority" key="2.2">
        <ThresholdsForm
          formData={highPriority}
          channelNames={channelNames}
          onFormDataChange={handleHighPriorityThresholdsChange}
          priority={'High'}
          resetToDefaults={resetToDefaults}
        />
      </TabPane>
    </Tabs>
  );
};

export default ApproachesThresholds;
