import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { Upload, Button, Modal, Input, Tooltip, Row, Col } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  useGetBatchStatusQuery,
  useRefreshBatchStatusMutation,
  useUploadBatchMutation,
} from '../../api';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';

const NO_OWNER_TAG = 'NO-OWNER';

const JobStatusModal = ({ visible, jobId, onClose, onFinish }) => {
  const {
    data,
    isFetching: isLoading,
    isError,
  } = useGetBatchStatusQuery(jobId);
  const [refresh, { startedTimeStamp }] = useRefreshBatchStatusMutation();

  const message = useMemo(() => {
    if (isError || !data) return 'Error loading job status. May have finished';

    const {
      Done: isDone,
      StartTimestamp: startTimestamp,
      NumberOfErrors: numberOfErrors,
      NumberOfMessagesBeingProcessed: numberOfMessagesBeingProcessed,
      NumberOfMessagesRemaining: numberOfMessagesRemaining,
    } = data;

    const startTime = moment(startTimestamp * 1000).format('M/D/YYYY h:mmA');
    const retrieveTime = moment().format('M/D/YYYY h:mm:ssA');

    const messageText = `
    ${isDone ? 'Done' : 'In Progress'} | Started: ${startTime}
    Errors: ${numberOfErrors}
    Messages being processed: ${numberOfMessagesBeingProcessed}
    Messages remaining: ${numberOfMessagesRemaining}
    
    Last updated: ${retrieveTime}`;

    return messageText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, startedTimeStamp]);

  const isComplete = useMemo(
    () => !isLoading && (isError || data?.Done),
    [data, isError, isLoading]
  );

  return (
    <Modal
      title={isError && 'Error'}
      visible={visible}
      onOk={() => (isComplete ? onFinish() : onClose())}
      onCancel={() => (isComplete ? onFinish() : onClose())}
      footer={[
        <Button
          key="refresh"
          type="text"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => refresh()}
          disabled={isLoading || isComplete}
        />,
        <Button
          key="submit"
          type="primary"
          onClick={() => (isComplete ? onFinish() : onClose())}
        >
          OK
        </Button>,
      ]}
    >
      <pre>{message}</pre>
    </Modal>
  );
};

const BatchImport = () => {
  const [email, setEmail] = useState(null);
  const [fileList, setFileList] = useState([]);
  const fileRef = useRef();

  const template = '/resources/templates/Batch Create Assets.xlsx';

  const handleDownload = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadBatch, uploadBatchResponse] = useUploadBatchMutation();
  const {
    data: uploadData,
    isLoading: isUploading,
    isError: isUploadError,
    reset,
  } = uploadBatchResponse;

  const [jobId, setJobId] = useState(null);

  useEffect(() => {
    if (!uploadData?.Id || isUploadError) return;
    setJobId(uploadData.Id);
  }, [uploadData, isUploadError]);

  useMutationNotification(uploadBatchResponse, {
    formatErrorNotification: ({ message }) => ({
      message: 'Upload Failed',
      description: message?.error,
    }),
    formatSuccessNotification: () => ({
      message: 'Upload Successful',
    }),
  });

  const handleUpload = async () => {
    const formData = new FormData();

    let owner = NO_OWNER_TAG;
    try {
      owner = email.split('@')[0].split('.').join('-');
      // eslint-disable-next-line no-empty
    } catch {}

    formData.append('file', fileList[0]);
    formData.append('type', fileList[0]?.name.split('.').pop());

    uploadBatch({ data: formData, email, owner });
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
    maxCount: 1,
    multiple: false,
  };

  return (
    <div style={{ display: 'flex', padding: '1rem 0rem' }}>
      <div className="BatchImport">
        <p className="text-left">Batch Create Entities</p>
        <Input
          placeholder="Enter your email address"
          type="email"
          prefix={<UserOutlined className="site-form-item-icon" />}
          suffix={
            <Tooltip title="An email will be sent to this email address if any error. Must confirm email on first batch creation">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
          onChange={(e) => setEmail(e.target.value)}
        />
        <Row gutter={[16, 0]}>
          <Col>
            <Upload {...props}>
              <Button
                disabled={fileList.length === 1}
                style={{ marginTop: 16 }}
                icon={<UploadOutlined />}
              >
                Select File
              </Button>
            </Upload>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={isUploading}
              style={{ marginTop: 16 }}
            >
              {isUploading ? 'Uploading' : 'Upload'}
            </Button>
          </Col>
          {jobId && (
            <Col>
              <Tooltip
                placement="right"
                title="Check status button will be disabled once you refresh or leave the page"
              >
                <Button
                  type="primary"
                  onClick={() => setIsModalVisible(true)}
                  disabled={!jobId}
                  style={{ marginTop: 16 }}
                >
                  Check Status ({jobId ? 1 : 0} Job{jobId ? '' : 's'})
                </Button>
              </Tooltip>
            </Col>
          )}
          {!jobId && (
            <Col>
              <a
                ref={fileRef}
                style={{ display: 'none' }}
                href={template}
                download={template.split('/').pop()}
              />
              <Button
                type="primary"
                onClick={handleDownload}
                icon={<DownloadOutlined />}
                style={{ marginTop: 16 }}
              >
                Template
              </Button>
            </Col>
          )}
        </Row>

        {jobId && (
          <JobStatusModal
            visible={isModalVisible}
            jobId={jobId}
            onClose={() => setIsModalVisible(false)}
            onFinish={() => {
              setIsModalVisible(false);
              setJobId();
              reset();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BatchImport;
