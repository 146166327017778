import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import Skeleton from '../../../../common/components/Skeleton';
import openNotification from '../../../../common/components/notification';
import ApproachesModal from '../ApproachesModal';
import Approach from '../Approach';
import ChannelLegend from '../ChannelLegend';
import {
  addApproach,
  setAssignedApproaches,
  setIntersectionSaved,
  startCreatingNewApproachMap,
  toggleEditingApproachMap,
  clearSelectedSegment,
  deleteSelectedSegment,
  stopCreatingNewApproachMap,
} from '../../store/slice';
import mapStyles from './mapStyles';
import { APPROACH_WIDTH } from '../../constants';
import { ApproachChannels } from '../../enums';
import { computeDistanceBetween, convertLatLngToOffset } from '../../utils';
import './style.css';

// Required libraries for drawing on map and using certain computation methods
const libraries = ['drawing', 'geometry'];

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
};

const ApproachesMap = ({ isLoading, lat, lng, approachMap }) => {
  const [approaches, setApproaches] = useState([]);
  const [drawingManager, setDrawingManager] = useState(undefined);
  const mapRef = useRef();
  const dispatch = useDispatch();

  const { approach, selectedSegment, isCreating } = useSelector(
    ({ configuration: { approachMapEditor } }) => approachMapEditor
  );
  const generalData = useSelector(
    (state) => state.configuration.intersectionGeneralData
  );

  const center = useMemo(
    () => ({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    }),
    [lat, lng]
  );

  const { isLoaded, _ } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
    libraries,
  });

  const createApproach = useCallback(
    (userPoints) => {
      if (isLoading || !isLoaded) return null;

      const approachData = {};
      const coordinates = [];

      let nearestPoint = userPoints[0];
      let nearestPointDistance = computeDistanceBetween(center, {
        lat: nearestPoint.lat(),
        lng: nearestPoint.lng(),
      });

      // Loop through all points and create an array of x & y coordinates for the approach segments
      // While creating the array, also find the nearest point to the intersection to determine the ordering of coordinates
      for (let i = 0; i < userPoints.length - 1; i++) {
        const startPoint = userPoints[i];
        const endPoint = userPoints[i + 1];

        const endPointDistance = computeDistanceBetween(center, {
          lat: endPoint.lat(),
          lng: endPoint.lng(),
        });

        if (endPointDistance < nearestPointDistance) {
          nearestPoint = endPoint;
          nearestPointDistance = endPointDistance;
        }

        const { x: startX, y: startY } = convertLatLngToOffset(
          startPoint,
          center
        );
        const { x: endX, y: endY } = convertLatLngToOffset(endPoint, center);

        coordinates[i] = {
          x: startX,
          y: startY,
          width: APPROACH_WIDTH,
        };
        coordinates[i + 1] = {
          x: endX,
          y: endY,
          width: APPROACH_WIDTH,
        };
      }

      if (
        nearestPoint.lat() === userPoints[0].lat() &&
        nearestPoint.lng() === userPoints[0].lng()
      )
        coordinates.reverse();

      approachData.approachName = '';
      approachData.numberOfCoordinates = userPoints.length;
      approachData.coordinates = coordinates;

      return approachData;
    },
    [isLoading, isLoaded, center]
  );

  const onPolylineComplete = useCallback(
    (polyline) => {
      const userPoints = polyline.getPath().getArray();
      if (userPoints.length < 2) return;

      const newApproach = createApproach(userPoints);
      dispatch(addApproach(newApproach));
      dispatch(
        setAssignedApproaches({
          channel: ApproachChannels.A,
        })
      );
      dispatch(setIntersectionSaved(false));

      // Remove original user polyline from map
      polyline.setMap(null);
    },
    [createApproach, dispatch]
  );

  const drawMap = useCallback(() => {
    if (isLoading || !isLoaded) return null;
    return approachMap?.map((ap, i) => ({
      approachNum: i,
      approachName: ap.approachName,
    }));
  }, [isLoading, isLoaded, approachMap]);

  useEffect(() => {
    if (isLoading || !isLoaded) return;
    if (!(approachMap && window.google)) return;

    setApproaches(drawMap());
  }, [approachMap, drawMap, isLoaded, isLoading]);

  const handleSelectedSegment = useCallback(
    (event) => {
      // Escape
      if (event.key === 'Escape') dispatch(clearSelectedSegment());
      // Delete
      if (event.key === 'Backspace' || event.key === 'Delete')
        dispatch(deleteSelectedSegment());
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleSelectedSegment);
    return () => window.removeEventListener('keydown', handleSelectedSegment);
  }, [handleSelectedSegment]);

  // Note: although this function is wrapped in useCallback, and does get recreated, any
  // changes to the dependencies will not be reflected in the actual use of the function.
  // This is because the function is passed to the GoogleMap component as the onLoad prop,
  // and the GoogleMap component will only call the function once, when the map is loaded
  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;
      const dm = new window.google.maps.drawing.DrawingManager({
        drawingControl: false,
      });
      dm.setMap(map);

      window.google.maps.event.addListener(
        dm,
        'polylinecomplete',
        onPolylineComplete
      );

      setDrawingManager(dm);
    },
    [onPolylineComplete]
  );

  return (
    <div>
      {!isLoading && isLoaded ? (
        <GoogleMap
          id="map"
          zoom={16}
          center={center}
          options={mapOptions}
          onLoad={onMapLoad}
        >
          <Marker
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 5,
            }}
            position={center}
          />
          <ApproachesModal
            isCreating={isCreating}
            approach={approach}
            selectedSegment={selectedSegment}
            onClearSelectedSegment={() => dispatch(clearSelectedSegment())}
            onDelete={() => dispatch(deleteSelectedSegment())}
            onCreate={(start) => {
              if (approaches.length === 10)
                openNotification({
                  message: 'Approach Map Error',
                  description:
                    'Intersections may have a maximum of 10 approaches.',
                });
              else
                dispatch(
                  start
                    ? startCreatingNewApproachMap()
                    : stopCreatingNewApproachMap()
                );
            }}
            toggleEdit={() => dispatch(toggleEditingApproachMap())}
            generalData={generalData}
            drawingManager={drawingManager}
          />
          {approaches?.map((ap) => (
            <Approach key={ap.approachNum} mapCenter={center} {...ap} />
          ))}
          <ChannelLegend />
        </GoogleMap>
      ) : (
        <Skeleton number={1} className="map__skeleton" active={isLoading} />
      )}
    </div>
  );
};

export default ApproachesMap;
