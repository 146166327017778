import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../redux/utils/client';
import { BFF_URL } from '../../../common/constants';

const api = createApi({
  reducerPath: 'api/auth',
  baseQuery,
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => ({
        url: `${BFF_URL}/current-user`,
        title: 'User Info',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCurrentUserQuery } = api;

export const { resetApiState } = api.util;

export default api;
