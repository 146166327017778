/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Timeframe } from '../enums';
import { selectAgencyId } from '../../features/userAuth/store/selectors';
import { PERIODS } from '../constants';
import { getPeakValues } from '../utils';
import useFeaturePersistence, {
  Feature,
} from '../../features/featurePersistence/hooks/useFeaturePersistence';
import {
  setOnTimeRange,
  setTimeperiod,
} from '../../features/featurePersistence/store/slice';

export const useTspAnalyticsFeature = () => {
  const dispatch = useDispatch();

  const agencyId = useSelector(selectAgencyId);

  const {
    feature: agencyInfo,
    update: updateFeature,
    updateResponse,
    isFetching: isLoading,
    isError,
    error,
  } = useFeaturePersistence({
    agencyId,
    featureName: Feature.TspAnalytics,
  });

  // Save timeperiod/on-time range filters
  useEffect(() => {
    if (!agencyInfo) return;
    dispatch(
      setTimeperiod([
        {
          start_time: agencyInfo?.peak_am_range?.start_time,
          label: 'peak_am',
          end_time: agencyInfo?.peak_am_range.end_time,
        },
        {
          start_time: agencyInfo?.peak_pm_range.start_time,
          label: 'peak_pm',
          end_time: agencyInfo?.peak_pm_range.end_time,
        },
      ])
    );
    dispatch(
      setOnTimeRange([
        parseInt(agencyInfo?.late_schedule_deviation_limit, 10),
        parseInt(agencyInfo?.early_schedule_deviation_limit, 10),
      ])
    );
  }, [agencyInfo, dispatch]);

  return {
    agencyInfo,
    isLoading,
    isError,
    error,
    editFeaturePersistanceTSPResponse: updateResponse,
    editFeaturePersistanceTSP: updateFeature,
  };
};

const useAgencyDefaults = () => {
  const agencyId = useSelector(selectAgencyId);
  const { agencyInfo, isLoading } = useTspAnalyticsFeature(agencyId);
  const [agencyPeriods, setAgencyPeriods] = useState(PERIODS);

  useEffect(() => {
    if (
      isLoading ||
      !agencyInfo ||
      !agencyInfo?.peak_am_range ||
      !agencyInfo?.peak_pm_range
    )
      return;

    const { peakAM, peakPM } = getPeakValues(
      agencyInfo?.peak_am_range,
      agencyInfo?.peak_pm_range
    );

    setAgencyPeriods([
      {
        label: `Peak AM (${peakAM})`,
        value: Timeframe.PeakAM,
      },
      {
        label: `Peak PM (${peakPM})`,
        value: Timeframe.PeakPM,
      },
      {
        label: 'Off-Peak (not peak or weekends)',
        value: Timeframe.OffPeak,
      },
      {
        label: 'Weekends (Saturday & Sunday)',
        value: Timeframe.Weekends,
      },
      {
        label: 'All',
        value: Timeframe.All,
      },
    ]);
  }, [isLoading, agencyInfo]);

  return { agencyId, agencyPeriods };
};
export default useAgencyDefaults;
