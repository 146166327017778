import { createSlice } from '@reduxjs/toolkit';
import { PEAKS_DEFIINITION, ON_TIME_RANGE } from '../../../common/enums';

const initialState = {
  timeperiod: PEAKS_DEFIINITION,
  onTimeRange: ON_TIME_RANGE,
};

const featurePersistence = createSlice({
  name: 'featurePersistence',
  initialState,
  reducers: {
    setTimeperiod: (state, { payload }) => {
      state.timeperiod = payload;
    },
    setOnTimeRange: (state, { payload }) => {
      state.onTimeRange = payload;
    },
  },
});

export const { setTimeperiod, setOnTimeRange } = featurePersistence.actions;
export default featurePersistence.reducer;
