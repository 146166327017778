import React, { useState, useMemo, useEffect } from 'react';
import { Menu } from 'antd';
import {
  MonitorOutlined,
  BarChartOutlined,
  SettingOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { dfs } from '../../../../common/utils/tree';
import { UserType } from '../../../../common/enums';
import { AgencyPriority } from '../../../../common/constants/values';
import useAgency from '../../../configuration/hooks/useAgency';

/** @todo remain commented until included feature release */
// import Monitoring from '../../../../common/icons/Monitoring';
// import Configuration from '../../../../common/icons/Configuration';
// import Dashboard from '../../../../common/icons/Dashboard';

import 'antd/lib/menu/style/css';
import './style.css';

const userTypeMenuItems = {
  [UserType.PowerUser]: [
    'transit-delay',
    'agency-settings',
    'intersections',
    'vehicles',
    'vps',
    'regions',
    'intersection-health-monitoring',
    'vehicles-health-monitoring',
    'activity-log',
  ],
  [UserType.AgencyViewer]: ['transit-delay'],
  [UserType.AgencyAdmin]: [
    'transit-delay',
    'agency-settings',
    'intersections',
    'vehicles',
    'vehicles-health-monitoring',
    'intersection-health-monitoring',
    'activity-log',
  ],
};

const generateCustomizedMenu = (userTypeItems, menuItems, agencyPriority) =>
  menuItems.reduce((displayedMenu, menuGroup) => {
    const items = menuGroup.items.filter((menuItem) => {
      if (
        agencyPriority === AgencyPriority.HIGH &&
        menuItem.key === 'transit-delay'
      ) {
        return false;
      }

      return userTypeItems.includes(menuItem.key);
    });

    if (items.length === 0) return displayedMenu;

    displayedMenu.push({ ...menuGroup, items });
    return displayedMenu;
  }, []);

const { SubMenu } = Menu;

const baseMenuItems = [
  {
    title: 'ANALYTICS',
    icon: <BarChartOutlined className="side-menu__icon" />,
    key: 'analytics',
    items: [
      {
        key: 'transit-delay',
        title: 'Transit Delay',
        to: '/analytics/transit-delay',
      },
    ],
  },
];

const allMenuItems = [
  ...baseMenuItems,
  {
    title: 'CONFIGURATION',
    icon: <SettingOutlined className="side-menu__icon" />,
    key: 'configuration',
    items: [
      {
        key: 'agency-settings',
        title: 'Agency Settings',
        to: `/config/agency-settings`,
      },
      {
        key: 'intersections',
        title: 'Intersections',
        to: `/config/intersections`,
      },
      {
        key: 'vehicles',
        title: 'Vehicles',
        to: `/config/vehicles`,
      },
      {
        key: 'vps',
        title: 'VPS',
        to: `/config/vps`,
      },
      {
        key: 'regions',
        title: 'Regions',
        to: `/regions`,
      },
    ],
  },
  {
    title: 'HEALTH MONITORING',
    icon: <MonitorOutlined className="side-menu__icon" />,
    key: 'health-monitoring',
    items: [
      {
        key: 'intersection-health-monitoring',
        title: 'Intersections',
        to: `/health-monitoring/intersections`,
      },
      {
        key: 'vehicles-health-monitoring',
        title: 'Vehicles',
        to: '/health-monitoring/vehicles',
      },
    ],
  },
  {
    title: 'LOGS & REPORTING',
    icon: <ClockCircleOutlined className="side-menu__icon" />,
    key: 'logs-reporting',
    items: [
      {
        key: 'activity-log',
        title: 'Activity Logs',
        to: `/logs-reporting/activity-log`,
      },
    ],
  },
];

const SideMenu = () => {
  const { userType, loading: isUserLoading } = useSelector(({ user }) => user);
  const { agency, isLoading: isAgencyLoading } = useAgency();

  const agencyPriority = agency?.priority;
  const loading = isUserLoading || isAgencyLoading;

  const [selectedKey, setSelectedKey] = useState('transit-delay');
  const [openKey, setOpenKey] = useState('analytics');

  const menuStructure = useMemo(() => {
    if (!loading && userType !== UserType.AgencyViewer) {
      setOpenKey('configuration');
      setSelectedKey('agency-settings');
    }

    if (loading || !agencyPriority) return baseMenuItems;

    return generateCustomizedMenu(
      userTypeMenuItems[userType] ?? ['transit-delay'],
      allMenuItems,
      agencyPriority
    );
  }, [loading, userType, agencyPriority]);

  const { pathname } = useLocation();

  // Match selected items in menu structure to current URL
  useEffect(() => {
    const matchingItem = dfs(
      { items: menuStructure },
      ({ items }) => items,
      ({ to }) => to === pathname
    );

    if (matchingItem && matchingItem.key !== openKey) {
      setSelectedKey(matchingItem.key);
    }
  }, [pathname, menuStructure, openKey]);

  return (
    !loading && (
      <Menu
        mode="inline"
        theme="light"
        style={{ borderRight: 0 }}
        defaultOpenKeys={[openKey]}
        selectedKeys={[selectedKey]}
        onSelect={({ key }) => setSelectedKey(key)}
      >
        {menuStructure.map((subMenu) =>
          subMenu.items.length ? (
            <SubMenu
              className="side-menu__sub-menu"
              key={subMenu.key}
              icon={subMenu.icon}
              title={subMenu.title}
            >
              {subMenu.items.map((item) => (
                <Menu.Item
                  className="side-menu__item"
                  key={item.key}
                  icon={item.icon}
                  disabled={item.disabled}
                >
                  <Link to={item.to || '/'}>{item.title}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              className="side-menu__item"
              key={subMenu.key}
              icon={subMenu.icon}
            >
              {subMenu.title}
            </Menu.Item>
          )
        )}
      </Menu>
    )
  );
};

export default SideMenu;
