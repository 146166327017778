// Error openNotification messages
export const ERROR_NOT_SUPPORTED =
  '405 - Smart Cities Platform does not currently support multiple agencies for a user.';
export const ERROR_NOT_CONFIGURED =
  '404 - User has no assigned agency. Contact GTT client services to configure agency permissions for this user.';
export const ERROR_NOT_SETUP =
  '404 - Agency has no data, please contact your GTT representative to finish setting up agency';
export const ERROR_NO_DATA = '404 - No data available for selected filters';
export const ERROR_REGION_HAS_CHILD_ASSETS =
  'ERROR - Region "{}" has one or more agencies associated to it. Please delete all associated agencies before attempting to delete a region.';
export const WARNING_AGENCY_HAS_CHILD_ASSETS =
  "WARNING - agency '{}' has child assets (vehicles and devices). Deleting this agency will delete ALL child assets associated with it. Confirm delete by selecting the box to the left and clicking the ‘Submit' button.";

// Error object for filling in content of ErrorPage component
export const ErrorMessages = {
  400: 'We were unable to process your request',
  401: 'Unauthorized',
  404: 'Agency not found:',
  500: 'Internal Server Error:',
};
