import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  HashRouter,
  Redirect,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider, useSelector } from 'react-redux';
import { errorHandler } from '../common/utils';
import ScrollToTop from '../common/components/scrollToTop';
import Login from '../features/userAuth/components/Login';
import LoginCallback from '../features/userAuth/components/LoginCallback';
import RegionPage from '../features/configuration/pages/regionPage';
import RegionsPage from '../features/configuration/pages/regionsPage';
import PrivateRoute from '../features/navigation/components/PrivateRoute';
import NavLayout from '../features/navigation/components/NavLayout';
import SignalDelayOverviewPage from '../features/travelTime/pages/overview';
import SignalDelayRouteDetailsPage from '../features/travelTime/pages/routeDetails';
import ErrorPage from '../features/errorPage';
import MioErrorPage from '../features/mioErrorPage';
import IntersectionsHealthMonitoring from '../features/healthMonitoring/pages/intersectionsHealthMonitoring/intersectionsHealthMonitoring';
/** @todo - REMAIN COMMENTED UNTIL RELEASED */
// import IntersectionsPage from '../features/healthMonitoring/pages/intersectionsPage';
import VehiclesHealthMonitoringPage from '../features/healthMonitoring/pages/vehiclesHealthMonitoringPage';
import VPSPage from '../features/configuration/pages/vpsPage';
import IntersectionsPage from '../features/configuration/pages/intersectionsPage';
import IntersectionPage from '../features/configuration/pages/intersectionPage';
import VehiclesPage from '../features/configuration/pages/vehiclesPage';
import AgencyPage from '../features/configuration/pages/agencyPage';
import ActivityLogs from '../features/reporting/pages/activityLog';
import CognitoAuthListener from '../features/userAuth/components/CognitoAuthListener';
import MioAuthListener from '../features/userAuth/components/MioAuthListener';
import store from '../redux/store';
import { IS_MIO_APP } from '../common/constants/environment';
import { UserType } from '../common/enums';
import { useStartSessionMutation } from '../features/reporting/api';

const Routes = () => {
  const { admin, userType, loading, agencyId } = useSelector(
    ({ user }) => user
  );
  const [startSession, { isLoading: isSessionLoading }] =
    useStartSessionMutation();

  useEffect(() => {
    if (!IS_MIO_APP) return;
    startSession();
  }, [startSession]);

  if (loading || (IS_MIO_APP && isSessionLoading)) return <></>;

  return (
    <Switch>
      {/* Client Services Admin Routes */}
      <PrivateRoute exact path="/regions">
        <RegionsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/regions/:regname">
        <RegionPage />
      </PrivateRoute>

      {!loading && admin && !agencyId && <Redirect to="/regions" />}
      <PrivateRoute exact path="/">
        {!loading && (admin || userType === UserType.AgencyAdmin)
          ? !loading && agencyId && <Redirect to="/config/agency-settings" />
          : !loading && <Redirect to="/analytics/transit-delay" />}
      </PrivateRoute>

      <PrivateRoute path="/logs-reporting">
        <NavLayout>
          <PrivateRoute exact path="/logs-reporting/activity-log">
            <ActivityLogs />
          </PrivateRoute>
        </NavLayout>
      </PrivateRoute>
      <PrivateRoute path="/analytics">
        <NavLayout>
          {/* Analytics - Schedule Deviation */}
          {/* <PrivateRoute exact path="/analytics/schedule-deviation">
        <ScheduleDeviationOverviewPage />
      </PrivateRoute> */}
          {/* <PrivateRoute
        exact
        path="/analytics/schedule-deviation/:route"
      >
        <ScheduleDeviationRouteDetailsPage />
      </PrivateRoute> */}
          {/* <PrivateRoute
        exact
        path="/analytics/schedule-deviation/:route/stop/:stop"
      >
        <ScheduleDeviationStopDetailsPage />
      </PrivateRoute> */}

          {/* Analytics - Transit Delay */}
          <PrivateRoute exact path="/analytics/transit-delay">
            <SignalDelayOverviewPage />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics/transit-delay/:route">
            <SignalDelayRouteDetailsPage />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/analytics/transit-delay/:route/intersection/:intersection" /> */}
        </NavLayout>
      </PrivateRoute>

      {/* Configuration - visible to only Admin users*/}
      <PrivateRoute path="/config">
        <NavLayout>
          <PrivateRoute exact path="/config/agency-settings">
            <AgencyPage />
          </PrivateRoute>

          {/* Intersections */}
          <PrivateRoute exact path="/config/intersections">
            <IntersectionsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/config/intersections/:intersectionId">
            <IntersectionPage />
          </PrivateRoute>

          {/* Vehicles */}
          <PrivateRoute exact path="/config/vehicles">
            <VehiclesPage />
          </PrivateRoute>

          <PrivateRoute exact path="/config/vps">
            <VPSPage />
          </PrivateRoute>
        </NavLayout>
      </PrivateRoute>

      <PrivateRoute exact path="/health-monitoring/intersections">
        <NavLayout>
          <IntersectionsHealthMonitoring />
        </NavLayout>
      </PrivateRoute>

      <PrivateRoute path="/health-monitoring">
        <NavLayout>
          <PrivateRoute exact path="/health-monitoring/intersections">
            <IntersectionsHealthMonitoring />
          </PrivateRoute>
          {/* Metrics - Schedule Deviation */}
          <PrivateRoute exact path="/health-monitoring/vehicles">
            <VehiclesHealthMonitoringPage />
          </PrivateRoute>
        </NavLayout>
      </PrivateRoute>

      {/* Error Routes */}
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
};

function RootApp() {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      {!IS_MIO_APP && (
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorPage} onError={errorHandler}>
            <ScrollToTop />
            <CognitoAuthListener>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/callback">
                  <LoginCallback />
                </Route>
                <Routes />
              </Switch>
            </CognitoAuthListener>
          </ErrorBoundary>
        </BrowserRouter>
      )}

      {IS_MIO_APP && (
        <HashRouter basename="/ocp">
          <ErrorBoundary
            FallbackComponent={MioErrorPage}
            onError={errorHandler}
          >
            <ScrollToTop />
            <MioAuthListener>
              <Switch>
                <Routes />
              </Switch>
            </MioAuthListener>
          </ErrorBoundary>
        </HashRouter>
      )}
    </div>
  );
}

const App = () => (
  <Provider store={store}>
    <RootApp />
  </Provider>
);
export default App;
