import { Metric, Make, Model } from '../enums';

// Colors
export const PALETTE = {
  HIGHLIGHT1: '#FB8658',
  HIGHLIGHT2: '#7A1EC2',
  HIGHLIGHT3: '#211DE1',
  HIGHLIGHT4: '#3AB0D8',
};

export const COLOR_ERROR = '#ff4d4f';
export const COLOR_WARNING = '#faad14';
export const COLOR_NORMAL = '#52c41a';
export const COLOR_POSITIVE = '#1b722f';

export const COLOR_ON_TIME = '#D8D8D8';
export const COLOR_EARLY = '#F8E9B5';

export const metricColorMap = {
  [Metric.OnTimePercentage]: PALETTE.HIGHLIGHT1,
  [Metric.ScheduleDeviation]: PALETTE.HIGHLIGHT2,
  [Metric.SignalDelay]: PALETTE.HIGHLIGHT1,
  [Metric.TravelTime]: PALETTE.HIGHLIGHT2,
  [Metric.DwellTime]: PALETTE.HIGHLIGHT3,
  [Metric.DriveTime]: PALETTE.HIGHLIGHT4,
};

export const makeColorMap = {
  [Make.GTT]: 'green',
  [Make.SierraWireless]: 'pink',
  [Make.Cradlepoint]: 'purple',
  [Make.Whelen]: 'orange',
  [Make.Teletrac]: 'blue',
};

export const modelColorMap = {
  [Model.GTT2100]: 'lightgrey',
  [Model.GTT2101]: 'lightgrey',
  [Model.IBR1700]: 'lightgrey',
  [Model.R1900]: 'lightgrey',
  [Model.MP70]: 'lightgrey',
  [Model.VSG]: 'lightgrey',
  [Model.IBR900]: 'lightgrey',
  [Model.Integration]: 'orange',
};
