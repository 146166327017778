import React from 'react';
import { useSelector } from 'react-redux';
import UploadFile from '../../../../common/components/UploadFile';
import { useUploadStaticIntersectionsMutation } from '../../api';
import './style.css';
import useAgency from '../../hooks/useAgency';

const UploadStaticIntersections = () => {
  const { agency } = useAgency();
  const { region } = useSelector(({ user }) => user);

  const [upload, { isLoading, error, data, isError, isSuccess }] =
    useUploadStaticIntersectionsMutation();

  const handleSubmit = (formData) => {
    upload({ data: formData, regionName: region, agencyName: agency?.name });
  };

  return (
    <div className="upload-static-intersections">
      <p className="text-left upload-static-intersections__title">
        Upload Static intersections
      </p>
      <UploadFile
        onSubmit={handleSubmit}
        modalContent={
          isSuccess ? 'Success' : isError && 'Error uploading intersections'
        }
        isLoading={isLoading}
        error={error}
        template="/resources/templates/Upload Static Intersections.csv"
      />
    </div>
  );
};

export default UploadStaticIntersections;
