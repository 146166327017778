/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form as AntForm, TimePicker, Card, Col, Row, Button } from 'antd';
import moment from 'moment';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import 'antd/dist/antd.css';
import './style.css';

const EditAgencySettings = ({
  editFeaturePersistanceTSP,
  createFPResponse,
}) => {
  const { timeperiod } = useSelector(
    ({ featurePersistence }) => featurePersistence
  );
  const [form] = AntForm.useForm();

  useEffect(
    () =>
      form.setFieldsValue({
        PeakAMFrom: moment(timeperiod[0]?.start_time, 'HH:mm:ss'),
        PeakAMTo: moment(timeperiod[0]?.end_time, 'HH:mm:ss'),
        PeakPMFrom: moment(timeperiod[1]?.start_time, 'HH:mm:ss'),
        PeakPMTo: moment(timeperiod[1]?.end_time, 'HH:mm:ss'),
      }),
    [form, timeperiod]
  );

  useMutationNotification(createFPResponse, {
    formatSuccessNotification: () => ({
      message: 'Peak Hours Updated',
    }),
    formatErrorNotification: ({ message }) => ({
      message: 'Error while updating agency settings',
      description: message,
    }),
  });

  const handleFormSubmit = (values) => {
    const peakPmRange = {};
    const peakAmRange = {};

    peakAmRange.start_time = values.PeakAMFrom.format('HH:mm:ss');
    peakAmRange.end_time = values.PeakAMTo.format('HH:mm:ss');
    peakPmRange.start_time = values.PeakPMFrom.format('HH:mm:ss');
    peakPmRange.end_time = values.PeakPMTo.format('HH:mm:ss');

    editFeaturePersistanceTSP({
      peak_am_range: peakAmRange,
      peak_pm_range: peakPmRange,
    });
  };

  return (
    <AntForm
      name="basic"
      autoComplete="off"
      form={form}
      onFinish={handleFormSubmit}
    >
      <Row gutter={20}>
        <Col span={13}>
          <div className="content-padding">
            <div className="site-card-border-less-wrapper">
              <Row gutter={24}>
                <Col className="gutter-row" span={19}>
                  <p className="card-title">Peak Hours</p>
                </Col>
                <Col className="gutter-row" span={3}>
                  <AntForm.Item>
                    <Button type="primary" htmlType="submit">
                      SAVE
                    </Button>
                  </AntForm.Item>
                </Col>
              </Row>
              <div style={{ marginTop: '-3%' }}>
                <Card>
                  <div className="card-padding-bottom">
                    <Row gutter={24}>
                      <Col className="gutter-row" span={14}>
                        <AntForm.Item
                          label={<b>Peak AM from</b>}
                          name="PeakAMFrom"
                        >
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            format="h:mm A"
                            minuteStep={30}
                          />
                        </AntForm.Item>
                      </Col>
                      <Col className="gutter-row" span={10}>
                        <AntForm.Item label={<b>to</b>} name="PeakAMTo">
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            format="h:mm A"
                            minuteStep={30}
                          />
                        </AntForm.Item>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
              <div className="card-padding-top">
                <Card>
                  <div className="card-padding-bottom">
                    <Row gutter={24}>
                      <Col className="gutter-row" span={14}>
                        <AntForm.Item
                          label={<b>Peak PM from</b>}
                          name="PeakPMFrom"
                        >
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            format="h:mm A"
                            minuteStep={30}
                          />
                        </AntForm.Item>
                      </Col>
                      <Col className="gutter-row" span={10}>
                        <AntForm.Item label={<b>to</b>} name="PeakPMTo">
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            format="h:mm A"
                            minuteStep={30}
                          />
                        </AntForm.Item>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </AntForm>
  );
};

export default EditAgencySettings;
