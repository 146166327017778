import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetIntersectionsQuery, useGetSignalDelayQuery } from '../api';
import { joinMetricsIntersections } from '../pages/routeDetails/shapes';
import { selectTimeperiod } from '../../featurePersistence/store/selectors';

const useIntersections = ({ routeName }) => {
  const { dateRange, direction, periods } = useSelector(
    ({ routeFilters }) => routeFilters
  );
  const timeperiod = useSelector(selectTimeperiod);
  const { data: signalDelay, isFetching: isSignalDelayLoading } =
    useGetSignalDelayQuery({
      routeName,
      dateRange,
      direction,
      periods,
      timeperiod,
    });

  const { data: intersectionLocations, isFetching: isLocationsLoading } =
    useGetIntersectionsQuery({
      routeName,
      direction,
      dateRange,
    });

  const signalDelayByLocation = useMemo(() => {
    if (!signalDelay?.length) return {};

    return signalDelay.reduce((map, sd) => {
      const { locationId } = sd;
      return { ...map, [locationId]: sd };
    }, {});
  }, [signalDelay]);

  // Add signal delay to locations
  const intersections = useMemo(() => {
    if (!intersectionLocations?.length) return [];

    return joinMetricsIntersections({
      locations: intersectionLocations,
      metrics: signalDelayByLocation,
    });
  }, [signalDelayByLocation, intersectionLocations]);

  const isLoading = useMemo(
    () => isSignalDelayLoading || isLocationsLoading,
    [isSignalDelayLoading, isLocationsLoading]
  );

  return { intersections, isLoading };
};

export default useIntersections;
