import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Select, Row, Col } from 'antd';
import {
  setIntersectionGeneralData,
  setIntersectionChannelNames,
  setIntersectionSaved,
  setIntersectionNtcipConfiguration,
} from '../../store/slice';
import { intersectionSchema } from '../../schemas';
import { ApproachChannels } from '../../enums';
import './style.css';

const TIME_ZONES = ['UTC', 'HST', 'PST', 'MST', 'CST', 'EST'];

const ApproachesGeneral = ({
  generalData,
  ntcipConfiguration,
  channelNames,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleGeneralDataChange = useCallback(() => {
    const {
      [ApproachChannels.A]: channelA,
      [ApproachChannels.B]: channelB,
      [ApproachChannels.C]: channelC,
      [ApproachChannels.D]: channelD,
      communicationType,
      controllerIpAddress,
      controllerPort,
      communityName,
      ...rest
    } = form.getFieldsValue();

    dispatch(setIntersectionSaved(false));
    dispatch(
      setIntersectionChannelNames([channelA, channelB, channelC, channelD])
    );
    dispatch(
      setIntersectionGeneralData({
        ...generalData,
        ...rest,
      })
    );

    dispatch(
      setIntersectionNtcipConfiguration({
        communicationType,
        communityName,
        controllerIpAddress,
        controllerPort,
      })
    );
  }, [form, dispatch, generalData]);

  const yupSync = {
    async validator({ field }, value) {
      await intersectionSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const [ntcipFlag, setNtcipFlag] = useState(true);
  useEffect(() => {
    if (form.getFieldValue('communicationType') === 'ntcip')
      setNtcipFlag(false);
    else setNtcipFlag(true);
  }, [form, ntcipConfiguration]);
  return (
    <Form
      form={form}
      className="general-form"
      layout="vertical"
      onFieldsChange={handleGeneralDataChange}
      initialValues={{
        ...generalData,
        [ApproachChannels.A]: channelNames[0],
        [ApproachChannels.B]: channelNames[1],
        [ApproachChannels.C]: channelNames[2],
        [ApproachChannels.D]: channelNames[3],
        ...ntcipConfiguration,
        location: 'Intersection',
      }}
    >
      <Row>
        <Col className="general-form-col" span={12}>
          <Form.Item
            className="form-item-style-1"
            name="location"
            label="Location Type"
          >
            <Select disabled />
          </Form.Item>
          <Form.Item
            className="form-item-style-1"
            name="communicationType"
            label="Communication Type"
          >
            <Select>
              <Select.Option value="ntcip" key="ntcip">
                NTCIP
              </Select.Option>
              <Select.Option value="centralized" key="centralized">
                Centralized
              </Select.Option>
              <Select.Option value="noAction" key="noAction">
                No Action Required
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item-style-3"
            name="controllerIpAddress"
            label="IP"
            rules={[yupSync]}
            hidden={ntcipFlag}
          >
            <Input disabled={ntcipFlag} />
          </Form.Item>
          <Form.Item
            className="form-item-style-3"
            name="controllerPort"
            label="UDP Port"
            rules={[yupSync]}
            hidden={ntcipFlag}
          >
            <Input disabled={ntcipFlag} />
          </Form.Item>
          <Form.Item
            className="form-item-style-1"
            name="communityName"
            label="Community Name"
            hidden={ntcipFlag}
            rules={[yupSync]}
          >
            <Input disabled={ntcipFlag} />
          </Form.Item>
          <h2 className="general-form-header">Channel names</h2>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.A}
            label="Channel A Name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.B}
            label="Channel B Name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.C}
            label="Channel C Name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.D}
            label="Channel D Name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <h2 className="general-form-header">Physical Details</h2>
          <Form.Item
            className="form-item-style-5"
            name="intersectionName"
            label="Intersection Name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="form-item-style-4"
            name="latitude"
            label="Latitude"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="form-item-style-4"
            name="longitude"
            label="Longitude"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="timezone"
            label="Time Zone"
            style={{ width: 'calc(55% - 8px)' }}
          >
            <Select>
              {TIME_ZONES.map((timezone) => (
                <Select.Option value={timezone} key={timezone}>
                  {timezone}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ApproachesGeneral;
