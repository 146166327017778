export const Direction = {
  Northbound: 'Northbound',
  Eastbound: 'Eastbound',
  Southbound: 'Southbound',
  Westbound: 'Westbound',
};

export const ApproachChannels = {
  A: 'Channel A',
  B: 'Channel B',
  C: 'Channel C',
  D: 'Channel D',
};

export const LocalStorageKeys = {
  Agency: 'Agency',
};

export const PreemptionValues = {
  Active: 'active',
  Inactive: 'inactive',
  Pending: 'pending',
};
