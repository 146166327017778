import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Tabs, Form, Input, Select, Row, Col } from 'antd';
import Button from '../../../../common/components/Button';
import openNotification from '../../../../common/components/notification';
import { Make, Model } from '../../../../common/enums';
import SelectRange from '../../../../common/components/SelectRange';
import { generateMakeOptions } from '../../utils';
import useDevices from '../../hooks/useDevices';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import { deviceSchema } from '../../schemas';
import './style.css';

const { TabPane } = Tabs;

const Pane = {
  VEHICLE: 'vehicle',
  DEVICE: 'device',
};

const VehicleAssignmentModal = ({
  vehicles,
  device,
  visible,
  onClose,
  setView,
  ...props
}) => {
  const [deviceMake, setDeviceMake] = useState(device?.make);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [creatingNewVehicle, setCreatingVehicle] = useState(false);
  const [deviceForm] = Form.useForm();

  const { agency } = useSelector(({ configuration }) => configuration);
  const { editDevice, associateDeviceResponse, associateDevice } = useDevices();
  const { isLoading } = associateDeviceResponse;

  useEffect(() => {
    setSelectedVehicle(null);
  }, [visible]);

  useMutationNotification(associateDeviceResponse, {
    formatSuccessNotification: () => ({ message: 'Success!' }),
    formatErrorNotification: () => ({
      message: 'Error Associating Device to Vehicle',
    }),
    onFinish: onClose,
  });

  useEffect(() => {
    deviceForm.resetFields();
    deviceForm.setFieldsValue(device);
    setDeviceMake(device?.make);
  }, [deviceForm, device]);

  const onSave = async (e) => {
    if (!selectedVehicle) {
      openNotification({
        message:
          'Please select a vehicle before attempting to associate a device',
        description: '',
      });
      return;
    }

    let values = { ...device };

    try {
      /**
       * @TODO Add functionality to save vehicle edits from this modal
       */
      if (deviceForm.isFieldsTouched()) {
        const formValues = deviceForm.getFieldsValue();

        await deviceSchema.validate(formValues, { abortEarly: false });

        values = {
          ...device,
          ...formValues,
        };

        if (
          formValues.addressMac !== '' &&
          formValues.make === Make.SierraWireless
        ) {
          values.addressMac = formValues.addressMac;
        }

        if (
          formValues.make === Make.SierraWireless ||
          formValues.make === Make.Cradlepoint
        )
          values.imei = formValues.imei;

        if (formValues.make === Make.GTT && device.model === Model.MP70) {
          values.model = Model.Model2101;
        } else if (formValues.make === Make.GTT) {
          values.model = formValues.model;
        } else if (formValues.make === Make.SierraWireless) {
          values.model = Model.MP70;
        }

        editDevice(values);
      }
      e.preventDefault();

      associateDevice({
        deviceId: values.id,
        vehicleId: selectedVehicle.id,
      });
    } catch (err) {
      openNotification({
        message: 'Error Associating Device',
        description: err,
      });
    }
  };

  const handleSelect = (vehicleId) => {
    const vehicle = vehicles?.find(({ id }) => id === vehicleId);

    if (!vehicle) throw Error('Invalid vehicle selected');

    setSelectedVehicle(vehicle);
  };

  return (
    <Modal
      className="device-assignment-modal"
      okText={'Assign'}
      onOk={onSave}
      onCancel={onClose}
      destroyOnClose={true}
      confirmLoading={isLoading}
      visible={visible}
      {...props}
    >
      <h2 className="h2-centered">Vehicle Assignment</h2>
      <Tabs>
        <TabPane tab="Vehicle Settings" key={Pane.VEHICLE}>
          {selectedVehicle === null && !creatingNewVehicle && (
            <div style={{ height: '415px' }}>
              <h2 className="h2-centered">Selection Options</h2>
              <p style={{ textAlign: 'center', margin: 0, marginBottom: '5%' }}>
                <span style={{ color: 'darkgrey' }}>
                  Pick a vehicle or create a new one
                </span>
              </p>
              <Select
                defaultValue="Existing Agency Vehicle"
                style={{
                  marginLeft: '28%',
                  marginRight: '28%',
                  width: '44%',
                }}
                onSelect={(values) => handleSelect(values)}
              >
                {vehicles?.map((vehicle) => (
                  <Select.Option key={vehicle.id} value={vehicle.id}>
                    {vehicle.name}
                  </Select.Option>
                ))}
              </Select>
              <p className="or">OR</p>
              <Button
                className="assign-device-button"
                type="primary"
                onClick={() => setView('CREATING_VEHICLE')}
              >
                Create New Vehicle
              </Button>
            </div>
          )}
          {selectedVehicle && (
            <Form
              className="vehicle-assignment-modal-form-general"
              layout="vertical"
              initialValues={{
                name: selectedVehicle?.name,
                description: selectedVehicle?.description,
                type: selectedVehicle?.type,
                vid: selectedVehicle?.vid,
                class: selectedVehicle?.class,
                priority: selectedVehicle?.priority,
                // preemptionLicense:
                //   selectedVehicle.preemptionLicense === 'active',
              }}
            >
              <Form.Item
                name="name"
                label="Vehicle Name"
                style={{ width: '45%' }}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="type"
                label="Vehicle Type"
                style={{ width: '25%' }}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="priority"
                label="Priority"
                style={{ width: '25%' }}
              >
                <Select disabled>
                  {agency.priority === 'High' ? (
                    <>
                      <Select.Option value="High">High</Select.Option>
                      <Select.Option value="Low">Low</Select.Option>
                    </>
                  ) : (
                    <Select.Option value="Low">Low</Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="vehicleCode"
                label={
                  <span>
                    <span style={{ fontWeight: 'bold' }}>Vehicle Code </span>
                    &#40;agency:class:veh id, 1:1:3&#41;
                  </span>
                }
                style={{ display: 'inline-block' }}
              >
                <Input.Group size="large">
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item className="vehicle-code-inputs" label="Agency">
                        <Select
                          style={{ width: '80%' }}
                          name="agency"
                          value={agency.agencyCode}
                          disabled
                        >
                          <Select.Option value="X">X</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        className="vehicle-code-inputs"
                        name="class"
                        label="Class"
                      >
                        <SelectRange
                          style={{ width: '80%' }}
                          disabled
                          min={1}
                          max={10}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        className="vehicle-code-inputs"
                        name="vid"
                        label="Vehicle ID"
                      >
                        <SelectRange
                          style={{ width: '80%' }}
                          disabled
                          min={1}
                          max={9999}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
              <Form.Item name="vid" label="Vehicle ID" style={{ width: '75%' }}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="description"
                label="GTT Description"
                style={{ width: '75%' }}
              >
                <Input disabled />
              </Form.Item>
              {/* <Form.Item
                name="preemptionLicense"
                valuePropName="checked"
                className="preemption-switch"
                label={<p>Pre-emption</p>}
              >
                <Switch style={{ marginLeft: '20px' }} />
              </Form.Item> */}
            </Form>
          )}
        </TabPane>
        <TabPane tab="Device Settings" key={Pane.DEVICE}>
          <Form
            form={deviceForm}
            className="edit-device-modal-form"
            layout="vertical"
          >
            <Form.Item
              className="edit-device-modal-form-item"
              name="id"
              label="Device ID"
              style={{ width: '40%' }}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="description"
              label="Description"
              style={{ width: '40%' }}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="make"
              label="Make"
              style={{ width: '52%' }}
            >
              <Select allowClear onChange={(value) => setDeviceMake(value)}>
                {generateMakeOptions()}
              </Select>
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="model"
              label="Model"
              style={{ width: '25%' }}
            >
              <Select allowClear>
                {deviceMake === Make.GTT ? (
                  <>
                    <Select.Option value="2100">2100</Select.Option>
                    <Select.Option value="2101">2101</Select.Option>
                  </>
                ) : deviceMake === Make.SierraWireless ? (
                  <Select.Option value="MP-70">MP-70</Select.Option>
                ) : deviceMake === Make.Cradlepoint ? (
                  <>
                    <Select.Option value="IBR900">IBR900</Select.Option>
                    <Select.Option value="IBR1700">IBR1700</Select.Option>
                    <Select.Option value="R1900">R1900</Select.Option>
                  </>
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="gttSerial"
              label="GTT Serial"
              style={{ width: '47%' }}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="serial"
              label="Device Serial"
              style={{ width: '47%' }}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="addressLan"
              label="LAN"
              style={{ width: '47%' }}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              className="edit-device-modal-form-item"
              name="addressWan"
              label="WAN"
              style={{ width: '47%' }}
            >
              <Input type="text" />
            </Form.Item>
            {(deviceMake === Make.SierraWireless ||
              deviceMake === Make.Cradlepoint) && (
              <>
                <Form.Item
                  className="edit-device-modal-form-item"
                  name="addressMac"
                  label="MAC"
                  style={{ width: '47%' }}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  className="edit-device-modal-form-item"
                  name="imei"
                  label="IMEI"
                  style={{ width: '47%' }}
                >
                  <Input type="text" />
                </Form.Item>
              </>
            )}
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default VehicleAssignmentModal;
