import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { Button, Row, Col, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ApproachesHelpModal from '../ApproachesHelpModal';
import { setAssignedApproaches, setIntersectionSaved } from '../../store/slice';
import ApproachesKeystrokesModal from '../ApproachesKeystrokesModal';
import { ApproachChannels } from '../../enums';
import { APPROACH_COLORS } from '../../constants';
import './style.css';

const spanStyleMouseOver = {
  cursor: 'pointer',
  color: '#1890ff',
  textDecoration: 'underline',
};

const spanStyleMouseOff = {
  cursor: 'pointer',
  color: 'rgba(0, 0, 0, 0.85)',
  textDecoration: 'none',
};

const ApproachesModalTableItems = ({
  approach,
  selectedApproachChannel,
  selectedSegment,
  onDelete,
}) => {
  const [deleteSpanStyle, setDeleteSpanStyle] = useState(spanStyleMouseOff);
  const { approachSegments } = approach || [];

  /** @TODO Implement these functions upon approval from Peter & Teigen */
  // const handleRowClick = useCallback(
  //   (index) => {
  //     const { approachSegments } = approach;
  //     const { segmentLength, segmentWidth } = approachSegments[index];

  //     dispatch(
  //       updateSelectedSegment({
  //         index,
  //         segmentLength,
  //         segmentWidth,
  //       })
  //     );
  //   },
  //   [approach, dispatch]
  // );

  // const handleMouseOverRow = () => {};

  return (
    <>
      {approachSegments?.map((segment, i) => {
        const isSelectedSegment = selectedSegment?.index === i;
        const { segmentLength, segmentWidth } = segment;
        const color = isSelectedSegment
          ? APPROACH_COLORS[selectedApproachChannel]
          : null;

        return (
          <Row
            key={i}
            style={{
              backgroundColor: color,
            }}
            // onMouseOver={
            //   isSelectedSegment ? null : () => handleMouseOverRow(i)
            // }
            // onClick={isSelectedSegment ? null : () => handleRowClick(i)}
          >
            <Col span={7} align="center">
              {i + 1}
            </Col>
            <Col span={7} align="center">
              {isSelectedSegment ? selectedSegment.segmentWidth : segmentWidth}m
            </Col>
            <Col span={7} align="center">
              {isSelectedSegment
                ? selectedSegment.segmentLength
                : segmentLength}
              m
            </Col>
            <Col span={3} align="center">
              {isSelectedSegment && (
                <DeleteOutlined
                  onMouseOver={() => setDeleteSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setDeleteSpanStyle(spanStyleMouseOff)}
                  onClick={onDelete}
                  style={deleteSpanStyle}
                />
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const ApproachesModal = ({
  isCreating,
  selectedSegment,
  approach,
  onClearSelectedSegment,
  onDelete,
  onCreate,
  generalData,
  drawingManager,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [helpSpanStyle, setHelpSpanStyle] = useState(spanStyleMouseOff);
  const [keystrokeSpanStyle, setKeystrokeSpanStyle] =
    useState(spanStyleMouseOff);
  const [closeSpanStyle, setCloseSpanStyle] = useState(spanStyleMouseOff);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [isKeystrokesModalVisible, setIsKeystrokesModalVisible] =
    useState(false);
  const [selectedApproachChannel, setSelectedApproachChannel] = useState(
    ApproachChannels.A
  );

  const { intersectionSaved } = useSelector((state) => state.configuration);

  const dispatch = useDispatch();

  const onHelpClose = useCallback(() => {
    setIsHelpModalVisible(false);
  }, []);

  const handleHelpClick = () => {
    setIsHelpModalVisible(true);
  };

  const onKeystrokesClose = useCallback(() => {
    setIsKeystrokesModalVisible(false);
  }, []);

  const handleKeystrokesClick = () => {
    setIsKeystrokesModalVisible(true);
  };

  const updateApproachChannel = useCallback(
    (value) => {
      const { approachNum } = approach;
      dispatch(
        setAssignedApproaches({
          channel: value,
          approachNum,
        })
      );
      setSelectedApproachChannel(value);
      if (intersectionSaved) dispatch(setIntersectionSaved(false));
    },
    [approach, dispatch, intersectionSaved]
  );

  useEffect(() => {
    setSelectedApproachChannel(approach?.approachChannel);
  }, [approach?.approachChannel]);

  useEffect(() => {
    if (selectedSegment) setIsCollapsed(false);
    if (!selectedSegment) setIsCollapsed(true);
  }, [selectedSegment]);

  useEffect(() => {
    if (!drawingManager) return;

    if (isCreating) {
      // eslint-disable-next-line no-undef
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
    } else drawingManager.setDrawingMode(null);
  }, [isCreating, drawingManager]);

  return (
    <>
      <Draggable>
        <div className="approaches-modal">
          <div className="approaches-modal-top">
            <Row>
              <p className="approaches-modal-title">
                {generalData.intersectionName}
              </p>
            </Row>
            <Row>
              <p className="approaches-modal-lat-lng">
                {generalData.latitude}, &nbsp; {generalData.longitude}
              </p>
              <Button
                type="secondary"
                size="small"
                onClick={() => onCreate(!isCreating)}
              >
                {isCreating ? 'Cancel Draw' : 'Draw Approach'}
              </Button>
            </Row>
          </div>
          {!isCollapsed && (
            <>
              <div className="approaches-modal-bottom">
                <Row>
                  <Col span={18}>
                    <div style={{ marginLeft: '5px' }}>
                      <h2
                        style={{
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        Selected Approach
                      </h2>
                      <p style={{ margin: '0px' }}>
                        {approach?.approachLength}m total length
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <h3 style={{ marginTop: '8px', marginBottom: '0px' }}>
                      Channel
                    </h3>
                    <Select
                      value={selectedApproachChannel}
                      onSelect={updateApproachChannel}
                    >
                      <Select.Option value={ApproachChannels.A}>
                        A
                      </Select.Option>
                      <Select.Option value={ApproachChannels.B}>
                        B
                      </Select.Option>
                      <Select.Option value={ApproachChannels.C}>
                        C
                      </Select.Option>
                      <Select.Option value={ApproachChannels.D}>
                        D
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
                <hr color="#8c8c8c" />
                <Row>
                  <Col span={7} align="center">
                    Segment
                  </Col>
                  <Col span={7} align="center">
                    Width
                  </Col>
                  <Col span={7} align="center">
                    Length
                  </Col>
                </Row>
                <ApproachesModalTableItems
                  approach={approach}
                  selectedApproachChannel={selectedApproachChannel}
                  selectedSegment={selectedSegment}
                  onDelete={onDelete}
                />
              </div>
              <div className="approaches-modal-tools">
                <p
                  className="approaches-modal-tools-text"
                  onMouseOver={() => setHelpSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setHelpSpanStyle(spanStyleMouseOff)}
                  onClick={handleHelpClick}
                  style={helpSpanStyle}
                >
                  Control Help
                </p>
                <p
                  className="approaches-modal-tools-text"
                  onMouseOver={() => setKeystrokeSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setKeystrokeSpanStyle(spanStyleMouseOff)}
                  onClick={handleKeystrokesClick}
                  style={keystrokeSpanStyle}
                >
                  Additional Keystrokes
                </p>
                <p
                  className="approaches-modal-tools-text"
                  onMouseOver={() => setCloseSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setCloseSpanStyle(spanStyleMouseOff)}
                  onClick={() => onClearSelectedSegment()}
                  style={closeSpanStyle}
                >
                  Close
                </p>
              </div>
            </>
          )}
        </div>
      </Draggable>
      <ApproachesHelpModal
        isVisible={isHelpModalVisible}
        onClose={onHelpClose}
      />
      <ApproachesKeystrokesModal
        isVisible={isKeystrokesModalVisible}
        onClose={onKeystrokesClose}
      />
    </>
  );
};

export default ApproachesModal;
