import React, { useEffect, useState } from 'react';
import {
  Form as AntForm,
  Input as AntInput,
  Select as AntSelect,
  Row,
  Col,
} from 'antd';
import { useSelector } from 'react-redux';
import skeletonAgency from '../../../../templates/skeleton_agency.json';
import { agencySchema } from '../../schemas';
import FormModal from '../../../../common/components/FormModal';
import openNotification from '../../../../common/components/notification';
import {
  timeZonesByCountry,
  stateCodesByCountry,
  getLabelForCountry,
  ModalSize,
} from '../../../../common/constants/values';
import { Integration } from '../../../../common/enums';
import { useCreateWhelenFeature } from '../../hooks/useWhelenIntegration';

const getAgencyIdFromResponse = (data) => {
  try {
    return /AgencyId: ([^ ]*).*/.exec(data)[1];
  } catch {
    return null;
  }
};

const { Option: AntOption } = AntSelect;

const CreateAgencyModal = ({
  agencies,
  visible,
  onResponseChange,
  onCancel,
  createAgency,
  response,
}) => {
  const { data, isLoading, isSuccess, isError, error } = response;

  const regionId = useSelector(({ user }) => user?.regionId);

  useEffect(() => {
    if (isSuccess) {
      onResponseChange();
      openNotification({
        message: 'Agency Created Successfully',
        type: 'success',
      });
    }
  }, [data, isSuccess, onResponseChange]);

  useEffect(() => {
    if (isError) {
      onResponseChange();
      openNotification({
        message: 'Error Creating Agency',
        description: error.message,
      });
    }
  }, [error?.message, isError, onResponseChange]);

  const handleSubmit = async (values) => {
    createAgency({
      ...skeletonAgency,
      ...values,
      regionId,
      agencyCode: +values.agencyCode,
      cmsId: values?.cmsId || null,
    });
  };

  // Integrations
  // NOTE: Integrations will be moved to agency admin screen
  const [integrations, setIntegrations] = useState([]);
  const [saveApiKey] = useCreateWhelenFeature();
  const [whelenApiKey, setWhelenApiKey] = useState();
  const [agencyId, setAgencyId] = useState(null);
  const [country, setCountry] = useState('');

  useEffect(
    () => setAgencyId(getAgencyIdFromResponse(response?.data)),
    [response?.data]
  );

  useEffect(() => {
    if (!agencyId || !whelenApiKey) return;
    saveApiKey({ agencyId, apiKey: whelenApiKey });
    setAgencyId(null);
  }, [agencyId, saveApiKey, whelenApiKey]);

  useEffect(() => {
    setIntegrations([]);
  }, [visible]);

  return (
    <FormModal
      onSubmit={handleSubmit}
      onCancel={onCancel}
      visible={visible}
      destroyOnClose={true}
      loading={isLoading}
      enableReinitialize
      validationSchema={agencySchema}
      validateOnChange={false}
      initialValues={skeletonAgency}
      agencies={agencies}
      width={ModalSize.Small}
    >
      {({
        handleSubmit: onSubmit,
        handleChange,
        values,
        isSubmitting,
        errors,
        setFieldValue,
      }) => (
        <div>
          <h3 className="create-agency">Create new Agency</h3>
          <AntForm
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            onSubmit={onSubmit}
            onChange={handleChange}
          >
            <Row>
              <Col span={12}>
                <AntForm.Item label="Name" help={errors.name} required>
                  <AntInput type="text" name="name" disabled={isSubmitting} />
                </AntForm.Item>
              </Col>
              <Col span={12}>
                <AntForm.Item label="Country" required>
                  <AntSelect
                    allowClear
                    name="country"
                    onChange={setCountry}
                    disabled={isSubmitting}
                  >
                    <AntOption value="United States">United States</AntOption>
                    <AntOption value="Canada">Canada</AntOption>
                  </AntSelect>
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <AntForm.Item label="Description" help={errors.description}>
                  <AntInput
                    type="text"
                    name="description"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </AntForm.Item>
              </Col>
              <Col span={12}>
                <AntForm.Item
                  label={getLabelForCountry(country)}
                  help={errors.state}
                  required
                >
                  <AntSelect
                    allowClear
                    name="state"
                    onChange={(value) => setFieldValue('state', value)}
                    disabled={isSubmitting}
                  >
                    {stateCodesByCountry[country]?.map((row) => (
                      <AntOption value={row.code} key={row.code}>
                        {row.code}
                      </AntOption>
                    ))}
                  </AntSelect>
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <AntForm.Item label="Priority" help={errors.priority} required>
                  <AntSelect
                    allowClear
                    name="priority"
                    onChange={(value) => setFieldValue('priority', value)}
                    disabled={isSubmitting}
                  >
                    <AntOption value="High">High</AntOption>
                    <AntOption value="Low">Low</AntOption>
                  </AntSelect>
                </AntForm.Item>
              </Col>
              <Col span={12}>
                <AntForm.Item label="City" help={errors.city} required>
                  <AntInput
                    type="text"
                    name="city"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <AntForm.Item
                  label="Agency Code"
                  help={errors.agencyCode}
                  required
                >
                  <AntInput
                    type="text"
                    name="agencyCode"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </AntForm.Item>
              </Col>
              <Col span={12}>
                <AntForm.Item label="Time Zone" help={errors.timezone} required>
                  <AntSelect
                    allowClear
                    name="timezone"
                    onChange={(value) => setFieldValue('timezone', value)}
                    disabled={isSubmitting}
                  >
                    {timeZonesByCountry[country]?.map((row) => (
                      <AntOption value={row.value} key={row.value}>
                        {row.name}
                      </AntOption>
                    ))}
                  </AntSelect>
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <AntForm.Item label="Integrations" help={errors.integrations}>
                  <AntSelect
                    mode="multiple"
                    allowClear
                    options={Object.entries(Integration).map(
                      ([value, label]) => ({
                        label,
                        value,
                      })
                    )}
                    disabled={isSubmitting}
                    onChange={setIntegrations}
                  />
                </AntForm.Item>
              </Col>
              <Col span={12}>
                <AntForm.Item label="CMS ID" help={errors.cmsId}>
                  <AntSelect
                    allowClear
                    onChange={(value) => setFieldValue('cmsId', value)}
                    disabled={isSubmitting}
                    defaultValue={''}
                  >
                    <AntOption value={''}>
                      <i>Create new CMS ID</i>
                    </AntOption>
                    {agencies &&
                      agencies.length &&
                      agencies
                        .filter(({ cmsId }) => !!cmsId)
                        .map((agency, index) => (
                          <AntOption key={index + 1} value={agency.cmsId}>
                            {agency.cmsId} ({agency.name})
                          </AntOption>
                        ))}
                  </AntSelect>
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                {/* Additional configuration options */}
                {integrations.includes(Integration.Whelen) && (
                  <AntForm.Item key="whelen" label="Whelen API Key" required>
                    <AntInput
                      required
                      onChange={(e) => setWhelenApiKey(e.target.value)}
                    />
                  </AntForm.Item>
                )}
              </Col>
            </Row>
          </AntForm>
        </div>
      )}
    </FormModal>
  );
};

export default CreateAgencyModal;
