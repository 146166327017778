import {
  Form as AntForm,
  Col,
  Row,
  Radio,
  Select as AntSelect,
  Switch,
  Button,
} from 'antd';
import { useState } from 'react';
import InfoTooltip from '../../../../common/components/InfoTooltip';
import SelectRange from '../../../../common/components/SelectRange';
import './style.css';

const { Option: AntOption } = AntSelect;

const ConditionalPriority = ({ occupancyData }) => {
  const [form] = AntForm.useForm();
  const [lateness, setLateness] = useState(2);
  const [showMinutes, setShowMinutes] = useState(false);
  const [calculationMode, setCalculationMode] = useState('');
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [occupancyValue, setOccupancyValue] = useState('Standing room only');
  const radioOptions = [
    {
      label: 'And',
      value: 'and',
    },
    {
      label: 'Or',
      value: 'or',
    },
  ];

  return (
    <AntForm name="basic" autoComplete="off" form={form} colon={false}>
      <Row>
        <Col span="12">
          <div className="padding-form">
            <Row>
              <Col span="24">
                <h2>Conditional Priority*</h2>
              </Col>
            </Row>
            <Row>
              <Col span="12">Lateness</Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item>
                      <SelectRange
                        style={{ width: '50px' }}
                        name="lateness"
                        value={lateness}
                        min={0}
                        max={10}
                        onChange={(value) => setLateness(value)}
                      />
                    </AntForm.Item>
                    <AntForm.Item label="Minutes">
                      <Switch
                        checked={showMinutes}
                        onChange={(checked) => setShowMinutes(checked)}
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="12">
                Calculation Mode
                <InfoTooltip text={''} className="custom-tooltip-margin" />
              </Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item label="">
                      <Radio.Group
                        options={radioOptions}
                        onChange={({ target: { value } }) =>
                          setCalculationMode(value)
                        }
                        value={calculationMode}
                        optionType="button"
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span="12">
                Minimum Occupancy
                <InfoTooltip text={''} className="custom-tooltip-margin" />
              </Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item className="custom-full-width">
                      <AntSelect
                        name="minimumOccupancy"
                        onChange={(value) => setOccupancyValue(value)}
                        value={occupancyValue}
                        className="custom-full-width"
                      >
                        {occupancyData.map((data) => (
                          <AntOption key={data.name} value={data.name}>
                            {data.name}
                          </AntOption>
                        ))}
                      </AntSelect>
                    </AntForm.Item>
                    <AntForm.Item style={{ marginLeft: '10px' }}>
                      <Switch
                        checked={isSwitchOn}
                        onChange={(checked) => setIsSwitchOn(checked)}
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="12" style={{ fontSize: '10px' }}>
                *Applies to all routes
              </Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" disabled={!isSwitchOn}>
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </AntForm>
  );
};

export default ConditionalPriority;
