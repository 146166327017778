import { useSelector } from 'react-redux';
import {
  useGetAgencyQuery,
  useGetOccupancyDataQuery,
  useEditAgencyMutation,
} from '../api';

// Returns the specified Agency's data and exposes methods for editing and creating an Agency
const useAgency = ({ id } = {}) => {
  const { agencyId: sessionAgencyId } = useSelector(({ user }) => user);

  const agencyId = id ?? sessionAgencyId;

  const {
    data: agency,
    isLoading,
    isError,
    error,
  } = useGetAgencyQuery(
    { id: agencyId },
    {
      skip: !agencyId,
    }
  );

  const { data: occupancyData } = useGetOccupancyDataQuery();
  // Invalidates Agency cache upon edit
  const [edit, editAgencyResponse] = useEditAgencyMutation();

  return {
    agency,
    occupancyData,
    isLoading,
    isError,
    error,
    editAgencyResponse,
    edit,
  };
};

export default useAgency;
