import React, { useState, useEffect, useCallback } from 'react';
import {
  Form as AntForm,
  Input as AntInput,
  Select,
  Switch,
  Row,
  Col,
} from 'antd';
import { editVehicleSchema } from '../../schemas';
import useVehicles from '../../hooks/useVehicles';
import { PreemptionValues } from '../../enums';
import FormModal from '../../../../common/components/FormModal';
import SelectRange from '../../../../common/components/SelectRange';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import openNotification from '../../../../common/components/notification';
import './style.css';

const EditVehicleModal = ({ onClose, vehicle = {}, agency = {}, ...props }) => {
  const { preemption } = vehicle;

  const [preemptionActive, setPreemptionActive] = useState(false);
  const [preemptionChanged, setPreemptionChanged] = useState(false);

  const { editVehicle, editVehicleResponse, changePreemptionResponse } =
    useVehicles();
  const { isLoading } = editVehicleResponse;
  const { status: preemptionStatus } = changePreemptionResponse;

  // Reset preemption status on vehicle change
  useEffect(() => {
    setPreemptionChanged(false);
  }, [vehicle?.id]);

  useEffect(() => {
    setPreemptionActive(preemption === PreemptionValues.Active);
  }, [preemption]);

  useMutationNotification(editVehicleResponse, {
    formatSuccessNotification: () => ({
      message: 'Vehicle Updated Successfully',
    }),
    formatErrorNotification: () => ({
      message: 'Error Editing Vehicle',
    }),
    onFinish: onClose,
  });

  useMutationNotification(changePreemptionResponse, {
    formatErrorNotification: () => ({
      message: 'Error Updating Pre-emption Status',
    }),
    formatSuccessNotification: () => ({
      message: 'Pre-emption Status Updated',
    }),
  });

  useEffect(() => {
    if (preemptionChanged && preemptionStatus === PreemptionValues.Pending)
      openNotification({
        message: 'Pre-emption Status Updating (this may take a minute)',
        type: 'info',
      });
  }, [preemptionChanged, preemptionStatus]);

  const handlePreemptionToggle = (checked) => {
    setPreemptionChanged(true);
    setPreemptionActive(checked);
  };

  const onSubmit = useCallback(
    async (values) => {
      editVehicle(
        {
          ...vehicle,
          ...values,
          class: +values.class,
          vid: +values.vid,
        },
        { preemptionChanged, preemptionActive }
      );
    },
    [editVehicle, preemptionActive, preemptionChanged, vehicle]
  );

  return (
    <FormModal
      className="edit-vehicle-modal"
      okText={'Save'}
      onSubmit={onSubmit}
      onCancel={onClose}
      validationSchema={editVehicleSchema}
      validateOnChange={false}
      loading={isLoading}
      initialValues={{ ...vehicle }}
      {...props}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <div>
          <h1 style={{ textAlign: 'center', margin: 0, marginBottom: '1%' }}>
            Vehicle {vehicle.name} Settings
          </h1>
          <AntForm
            className="edit-vehicle-modal-form"
            layout="vertical"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <AntForm.Item
              label="Vehicle Name"
              help={errors.name}
              style={{ width: '45%' }}
            >
              <AntInput
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
            </AntForm.Item>
            <AntForm.Item
              label="Vehicle Type"
              help={errors.type}
              style={{ width: '25%' }}
            >
              <AntInput
                type="text"
                name="type"
                value={values.type}
                onChange={handleChange}
              />
            </AntForm.Item>
            <AntForm.Item
              label="Priority"
              help={errors.priority}
              style={{ width: '25%' }}
            >
              <AntInput
                type="text"
                name="priority"
                value={values.priority}
                disabled
              />
            </AntForm.Item>
            <AntForm.Item
              label={
                <span>
                  <span style={{ fontWeight: 'bold' }}>Vehicle Code </span>
                  &#40;Agency:Class:VehicleID - Ex: 1:1:3&#41;
                </span>
              }
              style={{ display: 'inline-block' }}
            >
              <AntInput.Group size="large">
                <Row gutter={8}>
                  <Col span={8}>
                    <AntForm.Item
                      className="vehicle-code-inputs"
                      label="Agency"
                    >
                      <AntInput
                        style={{ width: '100%' }}
                        type="text"
                        name="agency"
                        value={agency.agencyCode}
                        disabled
                      />
                    </AntForm.Item>
                  </Col>
                  <Col span={8}>
                    <AntForm.Item className="vehicle-code-inputs" label="Class">
                      <SelectRange
                        style={{ width: '80%' }}
                        name="class"
                        value={values.class}
                        onChange={(value) => setFieldValue('class', value)}
                        min={1}
                        max={10}
                      />
                    </AntForm.Item>
                  </Col>
                  <Col span={8}>
                    <AntForm.Item
                      className="vehicle-code-inputs"
                      label="Vehicle ID"
                    >
                      <SelectRange
                        style={{ width: '100%' }}
                        name="vid"
                        value={values.vid}
                        onChange={(value) => setFieldValue('vid', value)}
                        min={1}
                        max={9999}
                        showSearch={true}
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
              </AntInput.Group>
            </AntForm.Item>
            <AntForm.Item
              label="GTT Description"
              help={errors.description}
              style={{ width: '75%' }}
            >
              <AntInput
                name="description"
                value={values.description}
                onChange={handleChange}
              />
            </AntForm.Item>
            {preemption === 'N/A' ? (
              <></>
            ) : (
              <AntForm.Item
                className="preemption-switch"
                label={<p>Pre-emption</p>}
              >
                <Switch
                  style={{ marginLeft: '20px' }}
                  onChange={(checked) => handlePreemptionToggle(checked)}
                  defaultChecked={preemption === PreemptionValues.Active}
                />
              </AntForm.Item>
            )}
          </AntForm>
        </div>
      )}
    </FormModal>
  );
};

export default EditVehicleModal;
