import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Polygon, Polyline, Circle, Marker } from '@react-google-maps/api';
import {
  updateIntersectionApproachMapSegment,
  updateSelectedSegment,
} from '../../store/slice';
import { computeDirection, computeDistanceBetween } from '../../utils';
import { CIRCLE_OPTIONS, MARKER_RADIUS } from '../../constants';
import { isSelectedSegment } from '../../store/selectors';

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  strokeWeight: 1,
  scale: 1,
};

const polygonOptions = {
  strokeOpacity: 0,
  strokeWeight: 0,
  fillOpacity: 1,
};

const polylineOptions = {
  strokeOpacity: 0,
  icons: [
    {
      icon: lineSymbol,
      offset: '0%',
      repeat: '5px',
    },
  ],
};

const arrowSymbol = {
  path: 'M 0,-2 0,4 -2,2 M 0,4 2,2',
  strokeWeight: 2,
  scale: 3,
};

const ApproachSegment = ({
  index,
  startPoint,
  endPoint,
  segmentWidth,
  segmentLength,
  approachLength,
  approachNum,
  color,
  onClick,
}) => {
  const [segmentKey, setSegmentKey] = useState(
    `segment-${approachNum}-${index}`
  );
  const [segmentCoords, setSegmentCoords] = useState([]);
  const [segmentArrowCoords, setSegmentArrowCoords] = useState(null);
  const [segmentMidPoint, setSegmentMidPoint] = useState(null);
  const [width, setWidth] = useState(segmentWidth);
  const [sideMarkers, setSideMarkers] = useState([]);
  const [sideMarkerData, setSideMarkerData] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [direction, setDirection] = useState(0);
  const dispatch = useDispatch();

  const isSelected = useSelector(isSelectedSegment(approachNum, index));

  const onSegmentClick = useCallback(() => {
    onClick(index, segmentLength, segmentWidth, approachLength);
  }, [approachLength, index, onClick, segmentWidth, segmentLength]);

  const onSideMarkerDrag = useCallback(
    (e, i) => {
      const { maps } = window.google;
      const widthAdjustment =
        computeDistanceBetween(
          sideMarkerData[i].center,
          new maps.LatLng(e.latLng.lat(), e.latLng.lng())
        ) / 2;
      const distBetweenMarkerAndSegmentCenter = computeDistanceBetween(
        sideMarkerData[i].center,
        segmentMidPoint
      );
      const distBetweenUserAndSegmentCenter = computeDistanceBetween(
        new maps.LatLng(e.latLng.lat(), e.latLng.lng()),
        segmentMidPoint
      );
      const distBetweenUserAndMarker = computeDistanceBetween(
        new maps.LatLng(e.latLng.lat(), e.latLng.lng()),
        sideMarkerData[i].center
      );
      const multiplier =
        distBetweenMarkerAndSegmentCenter > distBetweenUserAndSegmentCenter
          ? -1
          : 1;
      const updatedWidth = Math.min(
        Math.max(Math.round(width + widthAdjustment * multiplier), 30),
        285
      );
      // This is necessary for when the user is shrinking the segment's width, but they
      // move the mouse too far and end up on the other side of the segment's center
      const isMarkerCloserToUserThanSegmentCenter =
        distBetweenUserAndMarker < distBetweenUserAndSegmentCenter;

      if (!isMarkerCloserToUserThanSegmentCenter && width === 30)
        setWidth(width);
      else {
        setWidth(updatedWidth);
        dispatch(updateSelectedSegment({ segmentWidth: updatedWidth }));
      }
      setIsDirty(true);
    },
    [sideMarkerData, segmentMidPoint, width, dispatch]
  );

  const onSideMarkerDragEnd = useCallback(
    () =>
      dispatch(
        updateIntersectionApproachMapSegment({
          approachNum,
          index,
          segment: { width },
        })
      ),
    [approachNum, index, width, dispatch]
  );

  useEffect(() => {
    const { maps } = window.google;

    setSegmentKey(index);
    setSegmentArrowCoords(
      maps.geometry.spherical.interpolate(startPoint, endPoint, 0.7)
    );
    setSegmentMidPoint(
      maps.geometry.spherical.interpolate(startPoint, endPoint, 0.5)
    );

    const radius = width / 2;
    const directionWest = computeDirection(startPoint, endPoint);
    const directionEast = directionWest - 180;
    setDirection(directionWest);

    const pointOne = maps.geometry.spherical.computeOffset(
      startPoint,
      radius,
      directionEast
    );
    const pointTwo = maps.geometry.spherical.computeOffset(
      startPoint,
      radius,
      directionWest
    );
    const pointThree = maps.geometry.spherical.computeOffset(
      endPoint,
      radius,
      directionWest
    );
    const pointFour = maps.geometry.spherical.computeOffset(
      endPoint,
      radius,
      directionEast
    );

    const middlePointWest = maps.geometry.spherical.interpolate(
      pointOne,
      pointFour,
      0.5
    );
    const middlePointEast = maps.geometry.spherical.interpolate(
      pointTwo,
      pointThree,
      0.5
    );

    const smData = [
      {
        key: 0,
        center: middlePointWest,
        radius: MARKER_RADIUS,
      },
      {
        key: 1,
        center: middlePointEast,
        radius: MARKER_RADIUS,
      },
    ];
    setSideMarkerData(smData);
    setSideMarkers(
      smData.map((marker, i) => ({
        key: i,
        center: marker.center,
        radius: marker.radius,
        draggable: true,
        visible: isSelected,
        options: { ...CIRCLE_OPTIONS, strokeColor: color },
        onDrag: (e) => onSideMarkerDrag(e, i),
        onDragEnd: () => onSideMarkerDragEnd(),
      }))
    );
    setIsDirty(false);

    const approachCoords = [
      { lat: pointOne.lat(), lng: pointOne.lng() },
      { lat: pointTwo.lat(), lng: pointTwo.lng() },
      { lat: pointThree.lat(), lng: pointThree.lng() },
      { lat: pointFour.lat(), lng: pointFour.lng() },
      { lat: pointOne.lat(), lng: pointOne.lng() },
    ];
    setSegmentCoords(approachCoords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, startPoint, endPoint, color, isSelected, width, isDirty]);

  return (
    <>
      <Polygon
        key={`${segmentKey}-polygon`}
        paths={segmentCoords}
        options={{ ...polygonOptions, fillColor: color }}
        onClick={(e) => onSegmentClick(e)}
      />
      <Polyline
        key={`${segmentKey}-polyline`}
        path={segmentCoords}
        options={{ ...polylineOptions, strokeColor: 'black' }}
      />
      {segmentArrowCoords && (
        <Marker
          key={`${segmentKey}-arrow-marker`}
          position={{
            lat: segmentArrowCoords?.lat(),
            lng: segmentArrowCoords?.lng(),
          }}
          icon={{ ...arrowSymbol, rotation: direction + 90 }}
        />
      )}
      {sideMarkers.map((marker) => (
        <Circle key={marker.key} {...marker} />
      ))}
    </>
  );
};

export default ApproachSegment;
