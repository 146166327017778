import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageHeader, Tabs } from 'antd';
import ConfigurationLayout from '../../../../common/layouts/ConfigurationLayout';
import Button from '../../../../common/components/Button';
import MapTabs from '../../../../common/components/MapTabs';
import ApproachesMap from '../../components/ApproachesMap';
import ApproachesGeneral from '../../components/ApproachesGeneral';
import ApproachesThresholds from '../../components/ApproachesThresholds';
import ApproachesOutputs from '../../components/ApproachesOutputs';
import useIntersection from '../../hooks/useIntersection';
import * as defaultIntersectionData from '../../data/defaultDataIntersection.json';
import openNotification from '../../../../common/components/notification';
import {
  setIntersectionThresholds,
  setIntersectionOutputs,
  setIntersectionSaved,
  clearSelectedSegment,
} from '../../store/slice';
import './style.css';
import ConnectionErrorModal from '../../components/NoConnectionErrorModal';
import { NO_CONNECTION_ERROR_MESSAGE as UPDATE_ERROR_MESSAGE } from '../../constants';

let newIntersection;
const { TabPane } = Tabs;

const Pane = {
  GENERAL: 'general',
  THRESHOLDS: 'thresholds',
  OUTPUTS: 'outputs',
};

// Intersections L2 Page
const IntersectionPage = ({ match }) => {
  const { intersectionId } = match.params;

  const [isDelete, setIsDelete] = useState(false);

  const dispatch = useDispatch();

  const {
    intersection,
    isLoading,
    refresh,
    update,
    updateError,
    updateSuccess,
  } = useIntersection({
    intersectionId,
  });

  const isSaved = useSelector(
    ({ configuration }) => configuration.intersectionSaved
  );
  const generalData = useSelector(
    ({ configuration }) => configuration.intersectionGeneralData
  );
  const approachMap = useSelector(
    ({ configuration }) => configuration.intersectionApproachMap
  );
  const thresholds = useSelector(
    ({ configuration }) => configuration.intersectionThresholds
  );
  const channels = useSelector(
    ({ configuration }) => configuration.intersectionChannels
  );
  const outputs = useSelector(
    ({ configuration }) => configuration.intersectionOutputs
  );
  const channelNames = useSelector(
    ({ configuration }) => configuration.intersectionChannelNames
  );
  const ntcipConfiguration = useSelector(
    ({ configuration }) => configuration.intersectionNtcipConfiguration
  );

  useEffect(() => {
    if (!updateError) return;
    ConnectionErrorModal(update, newIntersection, UPDATE_ERROR_MESSAGE);
    setIntersectionSaved(false);
  }, [updateError]);

  useEffect(() => {
    if (!updateSuccess) return;
    if (updateSuccess && !isDelete) {
      openNotification({
        message: 'Successfully updated intersection',
        type: 'success',
      });
    } else {
      openNotification({
        message: 'Successfully deleted intersection',
        type: 'success',
      });
    }
  }, [updateSuccess, isDelete]);

  const onSave = useCallback(() => {
    newIntersection = {
      ...intersection,
      ...generalData,
      ntcipConfiguration,
      approachMap,
      thresholds,
      channels,
      outputs,
    };
    setIsDelete(false);
    dispatch(setIntersectionSaved(true));
    dispatch(clearSelectedSegment());
    update(newIntersection);
  }, [
    intersection,
    generalData,
    approachMap,
    thresholds,
    channels,
    ntcipConfiguration,
    outputs,
    dispatch,
    update,
  ]);

  const onCancel = useCallback(() => {
    refresh();
  }, [refresh]);

  const resetThresholdsToDefaults = () => {
    dispatch(
      setIntersectionThresholds(defaultIntersectionData.default.thresholds)
    );
  };

  const resetOutputsToDefaults = () => {
    dispatch(setIntersectionOutputs(defaultIntersectionData.default.outputs));
  };

  const onDelete = () => {
    const resetIntersection = { ...intersection, ...defaultIntersectionData };
    update(resetIntersection);
    setIsDelete(true);
  };

  return (
    <ConfigurationLayout>
      <div
        className={`approach-container approach-container-is-saved-${isSaved}`}
      >
        {!isSaved && (
          <PageHeader
            title="Now Editing Intersection"
            extra={[
              <Button key="3" type="danger" onClick={onDelete}>
                Delete
              </Button>,
              <Button key="2" type="secondary" onClick={onCancel}>
                Cancel
              </Button>,
              <Button key="1" onClick={onSave}>
                Save
              </Button>,
            ]}
          />
        )}
        <MapTabs
          defaultActiveKey={Pane.GENERAL}
          tabs={
            <>
              <TabPane tab="General" key={Pane.GENERAL}>
                <ApproachesGeneral
                  generalData={generalData}
                  ntcipConfiguration={ntcipConfiguration}
                  channelNames={channelNames}
                />
              </TabPane>
              <TabPane tab="Thresholds" key={Pane.THRESHOLDS}>
                <ApproachesThresholds
                  thresholds={thresholds}
                  channelNames={channelNames}
                  resetToDefaults={resetThresholdsToDefaults}
                />
              </TabPane>
              <TabPane tab="Outputs" key={Pane.OUTPUTS}>
                <ApproachesOutputs
                  outputs={outputs}
                  channelNames={channelNames}
                  resetToDefaults={resetOutputsToDefaults}
                />
              </TabPane>
            </>
          }
          tabsLoading={isLoading}
          map={
            <ApproachesMap
              isLoading={isLoading}
              lat={isLoading ? 0 : intersection.latitude}
              lng={isLoading ? 0 : intersection.longitude}
              approachMap={approachMap}
            />
          }
        />
      </div>
    </ConfigurationLayout>
  );
};

export default withRouter(IntersectionPage);
