import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import StatusCard from '../../components/StatusCard';
import IntersectionsMap from '../../components/IntersectionsMap';
import IntersectionsList from '../../components/IntersectionsList';
import MapIcon from '../../../../common/icons/Map';
import 'antd/dist/antd.css';
import './style.css';
import TabView from '../../../../common/components/TabView';
import { useGetIntersectionsListQuery } from '../../api';
import TableSearch from '../../../../common/components/TableSearch';
import Skeleton from '../../../../common/components/Skeleton';
import HealthMonitoringLayout from '../../../../common/layouts/HealthMonitoringLayout';
import { TooltipText } from '../../constants';

const View = {
  Map: 'Map',
  List: 'List',
};

const IntersectionsHealthMonitoringPage = () => {
  const [selectedMapIntersections, setSelectedMapIntersections] = useState([]);

  const [view, setView] = useState(View.Map);

  const [searchKeys, setSearchKeys] = useState([]);
  const { data: intersectionsList, isLoading: isIntersectionsMapLoading } =
    useGetIntersectionsListQuery();
  const [healthStatusValues, setHealthStatusValues] = useState({
    normal: 0,
    warning: 0,
    error: 0,
  });
  useEffect(() => {
    if (!isIntersectionsMapLoading && intersectionsList) {
      setHealthStatusValues({
        normal: intersectionsList.filter(
          (intersection) => intersection.status === 'NORMAL'
        ).length,
        warning: intersectionsList.filter(
          (intersection) => intersection.status === 'WARNING'
        ).length,
        error: intersectionsList.filter(
          (intersection) => intersection.status === 'ERROR'
        ).length,
      });
    }
  }, [intersectionsList, isIntersectionsMapLoading]);
  const currentDateTime = new Date();
  const currentDate = `${
    currentDateTime.getMonth() + 1
  }/${currentDateTime.getDate()}/${currentDateTime.getFullYear()}`;
  const currentTime = `${
    currentDateTime.getHours() > 12
      ? currentDateTime.getHours() - 12
      : currentDateTime.getHours()
  }:${String(currentDateTime.getMinutes()).padStart(2, '0')} ${
    currentDateTime.getHours() > 12 ? 'PM' : 'AM'
  }`;
  useEffect(() => {
    setSearchKeys([]);
  }, []);
  const [tableDataFilter, setTableDataFilter] = useState(intersectionsList);
  useEffect(() => {
    setTableDataFilter(() => {
      if (searchKeys.length > 0) {
        return intersectionsList.filter((index) =>
          searchKeys.some((key) =>
            index.intersectionName.toLowerCase().includes(key.toLowerCase())
          )
        );
      }
      return intersectionsList;
    });
  }, [searchKeys, intersectionsList]);

  return (
    <main className="IntersectionPage">
      <HealthMonitoringLayout
        title="Health Monitoring"
        tooltip={TooltipText.IntersectionHealthMonitoring}
        headerChildren={
          !isIntersectionsMapLoading ? (
            <StatusCard
              normal={healthStatusValues.normal}
              warning={healthStatusValues.warning}
              error={healthStatusValues.error}
            />
          ) : (
            <Skeleton active={isIntersectionsMapLoading} />
          )
        }
      >
        <TabView
          className="map-list"
          value={view}
          onChange={(value) => setView(value)}
          views={
            <>
              <MapIcon value={View.Map} />
              <UnorderedListOutlined value={View.List} />
            </>
          }
          viewTabs={true}
        >
          <div className="map-list__body">
            <div className="tab__header">
              <div className="updated-time">
                {!isIntersectionsMapLoading ? (
                  <h4>
                    Updated {currentDate} at {currentTime}
                  </h4>
                ) : (
                  <Skeleton active={isIntersectionsMapLoading} />
                )}
              </div>
              {view === View.List && (
                <div className="tab__header__search">
                  <TableSearch
                    data={intersectionsList}
                    handleSearch={setSearchKeys}
                    itemSearchField="intersectionName"
                    searchKeys={searchKeys}
                    placeholder="Enter intersection name"
                  />
                </div>
              )}
              {
                <Segmented
                  options={[
                    {
                      value: View.Map,
                      label: 'Map',
                      icon: <MapIcon value={View.Map} />,
                    },
                    {
                      value: View.List,
                      label: 'List',
                      icon: <UnorderedListOutlined value={View.List} />,
                    },
                  ]}
                  onChange={(value) => setView(value)}
                />
              }
            </div>
            {isIntersectionsMapLoading && (
              <Skeleton
                className="skeleton-map"
                active={isIntersectionsMapLoading}
              />
            )}
            {view === View.Map && !isIntersectionsMapLoading && (
              <>
                <div className="map__settings">
                  <IntersectionsMap
                    className="map-list__settings"
                    isMarkerShown
                    intersections={intersectionsList}
                    selectedIntersections={selectedMapIntersections}
                  />
                </div>
              </>
            )}
            {view === View.List && !isIntersectionsMapLoading && (
              <>
                <IntersectionsList
                  intersections={tableDataFilter}
                  isLoading={isIntersectionsMapLoading}
                />
              </>
            )}
          </div>
        </TabView>
      </HealthMonitoringLayout>
    </main>
  );
};

export default IntersectionsHealthMonitoringPage;
