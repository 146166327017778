import { createApi, retry } from '@reduxjs/toolkit/query/react';
import {
  ASSET_LIBRARY_API_URL,
  VPS_URL,
  INTERSECTIONS_URL,
  WHELEN_URL,
} from '../../../common/constants';
import { baseQuery, mockQuery } from '../../../redux/utils/client';
import { camelize, snakeize, trim } from '../../../common/utils/string';
import { IS_MIO_APP } from '../../../common/constants/environment';
import { getOccupancyData } from '../data';
/**
 * Wait for a certain number of milliseconds
 * @param {integer} ms
 * @returns {Promise}
 */
const wait = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Handle transforming raw string and lower camel-case objects/arrays
 * @param {string|object|array} d
 * @returns {string|object|array}
 */
const transformResponse = (d) => {
  if (!d) return d;

  try {
    d = JSON.parse(d);

    if (Array.isArray(d)) return d.map((item) => camelize(item));
    d = camelize(d);

    // eslint-disable-next-line no-empty
  } catch {}

  // Remove any literal quotes
  if (typeof d === 'string') return trim(d, '"');

  return d;
};

/**
 * Transform multiple sets of query args to multiple queries and return
 * the results as an array, or an error object if one of the queries fails
 */
const multiQuery = async (args, { query }, callback) => {
  const errors = [];
  const data = await Promise.all(
    args.map(async (arg) => {
      const response = await query(callback(arg));
      if (response?.error) errors.push(response?.error);
      return response?.data;
    })
  );
  return errors?.length ? { error: errors } : { data };
};

/**
 * Initiate a preemption update
 */
const preemptionQuery = async (args, { query }, changePreemption) => {
  const response = await query(changePreemption(args));
  const queueUrl = response?.data?.QueueUrl;
  if (queueUrl) {
    return { data: { message: 'Updating Pre-emption Status', queueUrl } };
  }
  return { error: { message: 'Unable to Initiate Pre-emption Status Update' } };
};

/**
 * Get the most recent status of a preemption update
 */
const preemptionStatusQuery = async (
  args,
  { query },
  getChangePreemptionStatus
) => {
  const { queueUrl, _ } = args;

  const error = { message: 'Unable to Update Preemption Status' };
  const data = 'Successfully Updated Preemption Status';

  const {
    data: changePreemptionStatus,
    error: changePreemptionStatusError,
    // eslint-disable-next-line no-await-in-loop
  } = await query(getChangePreemptionStatus(queueUrl));

  if (!changePreemptionStatus) return { error };

  const {
    Done: isDone,
    NumberOfErrors: numberOfErrors,
    NumberOfMessagesRemaining: numberOfMessagesRemaining,
  } = changePreemptionStatus;

  if (
    !isDone ||
    changePreemptionStatusError ||
    numberOfErrors > 0 ||
    (numberOfMessagesRemaining === 0 && isDone !== true)
  )
    return { error };

  return { data };
};

/**
 * Wrap preemptionStatusQuery in a retry function to handle polling for preemption status
 */
const preemptionStatusQueryWithRetry = retry(preemptionStatusQuery, {
  maxRetries: 9,
});

const api = createApi({
  reducerPath: 'api/configuration',
  baseQuery,
  tagTypes: [
    'VPSS',
    'INTERSECTIONS',
    'INTERSECTION',
    'REGIONS',
    'REGION',
    'AGENCIES',
    'AGENCY',
    'VEHICLES',
    'VEHICLE',
    'DEVICES',
    'AVAILABLE_DEVICES',
    'ASSOCIATED_DEVICES',
    'DEVICE',
    'BATCH_JOB',
  ],
  endpoints: (builder) => ({
    /** VPS */

    getVPSS: builder.query({
      query: ({ agencyName }) => ({
        url: VPS_URL,
        title: 'Server Table',
        method: 'GET',
        params: {
          customerName: agencyName.toUpperCase(),
        },
      }),
      providesTags: ['VPSS'],
    }),

    editVPSS: builder.mutation({
      query: (data) => ({
        url: VPS_URL,
        title: 'Edit VPS',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['VPSS'],
    }),

    createVPSS: builder.mutation({
      query: (data) => ({
        url: VPS_URL,
        title: 'Create VPS',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['VPSS'],
    }),

    // Forces refetch of any queries with 'VPSS' tag
    refreshVPSS: builder.mutation({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['VPSS'],
    }),

    /** Intersections */

    getIntersections: builder.query({
      query: () => ({
        url: `${INTERSECTIONS_URL}/intersections`,
        title: 'Intersections',
        method: 'GET',
      }),
      providesTags: ['INTERSECTIONS'],
    }),

    createIntersection: builder.mutation({
      query: ({ intersection }) => ({
        url: `${INTERSECTIONS_URL}/intersections`,
        title: 'Intersections',
        method: 'POST',
        data: intersection,
      }),
      invalidatesTags: ['INTERSECTIONS', 'INTERSECTION'],
    }),

    getIntersection: builder.query({
      query: ({ intersectionId }) => ({
        url: `${INTERSECTIONS_URL}/intersections/${intersectionId}`,
        title: 'Intersection',
        method: 'GET',
      }),
      providesTags: ['INTERSECTION'],
    }),

    updateIntersection: builder.mutation({
      query: ({ intersection }) => ({
        url: `${INTERSECTIONS_URL}/intersections/${intersection.intersectionId}`,
        title: 'Intersection',
        method: 'PUT',
        data: intersection,
      }),
      invalidatesTags: ['INTERSECTIONS', 'INTERSECTION'],
    }),

    refreshIntersections: builder.mutation({
      query: () => ({
        url: `${INTERSECTIONS_URL}/intersections/refresh`,
        title: 'Refresh Intersections',
        method: 'PUT',
      }),
      invalidatesTags: ['INTERSECTIONS', 'INTERSECTION'],
    }),

    refreshIntersection: builder.mutation({
      queryFn: () => ({}),
      invalidatesTags: ['INTERSECTION'],
    }),

    // CMS endpoint
    setCMS: builder.mutation({
      query: ({ data, headers }) => ({
        url: VPS_URL,
        title: 'CMS',
        method: 'POST',
        headers,
        data,
      }),
    }),

    // CSV endpoint
    getCSV: builder.mutation({
      query: ({ data, params, headers, URL }) => ({
        url: URL,
        title: 'CSV',
        method: 'POST',
        params,
        headers,
        data,
      }),
      invalidatesTags: [
        'REGIONS',
        'REGION',
        'AGENCIES',
        'AGENCY',
        'VEHICLES',
        'VEHICLE',
        'DEVICES',
        'AVAILABLE_DEVICES',
        'DEVICE',
        'INTERSECTIONS',
      ],
    }),

    /** Asset Library */

    // Region endpoints
    getRegions: builder.query({
      query: () => ({
        url: `${ASSET_LIBRARY_API_URL}/regions`,
        title: 'Regions',
        method: 'GET',
        transformResponse,
      }),
      providesTags: ['REGIONS'],
    }),

    getRegion: builder.query({
      query: ({ id }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/regions/${id}`
          : `${ASSET_LIBRARY_API_URL}/region`,
        title: 'Region',
        method: 'GET',
        params: { id },
        transformResponse,
      }),
      providesTags: ['REGION'],
    }),

    editRegion: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/regions/${data.id}`
          : `${ASSET_LIBRARY_API_URL}/region`,
        title: 'Edit Region',
        method: 'PUT',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: ['REGION', 'REGIONS'],
    }),

    createRegion: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/regions`
          : `${ASSET_LIBRARY_API_URL}/region`,
        title: 'Create Region',
        method: 'POST',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: ['REGION', 'REGIONS'],
    }),

    deleteRegion: builder.mutation({
      queryFn: async (regions, _, __, query) =>
        multiQuery(regions, { query }, (id) => ({
          url: IS_MIO_APP
            ? `${ASSET_LIBRARY_API_URL}/regions/${id}`
            : `${ASSET_LIBRARY_API_URL}/region`,
          title: 'Delete Region',
          method: 'DELETE',
          data: { id },
          transformResponse,
        })),
      invalidatesTags: ['REGION', 'REGIONS'],
    }),

    // Agency endpoints
    getAgencies: builder.query({
      query: ({ regionId }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/agencies`
          : `${ASSET_LIBRARY_API_URL}/agencies/region`,
        title: 'Agencies',
        method: 'GET',
        params: IS_MIO_APP ? { regionId } : { id: regionId },
        transformResponse,
      }),
      providesTags: ['AGENCIES'],
    }),

    getAgency: builder.query({
      query: ({ id }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/agencies/${id}`
          : `${ASSET_LIBRARY_API_URL}/agency`,
        title: 'Agency',
        method: 'GET',
        params: { id },
        transformResponse,
      }),
      providesTags: ['AGENCY'],
    }),

    editAgency: builder.mutation({
      query: ({ id, ...data }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/agencies/${id}`
          : `${ASSET_LIBRARY_API_URL}/agency`,
        title: 'Edit Agency',
        method: 'PUT',
        data: IS_MIO_APP ? { id, ...data } : snakeize({ id, ...data }),
        params: { id },
        transformResponse,
      }),
      invalidatesTags: ['AGENCY', 'AGENCIES'],
    }),

    createAgency: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/agencies`
          : `${ASSET_LIBRARY_API_URL}/agency`,
        title: 'Create Agency',
        method: 'POST',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: ['AGENCY', 'AGENCIES'],
    }),

    deleteAgency: builder.mutation({
      queryFn: async (agencies, _, __, query) =>
        multiQuery(agencies, { query }, (id) => ({
          url: IS_MIO_APP
            ? `${ASSET_LIBRARY_API_URL}/agencies/${id}`
            : `${ASSET_LIBRARY_API_URL}/agency`,
          title: 'Delete Agency',
          method: 'DELETE',
          data: { id },
        })),
      invalidatesTags: ['AGENCIES', 'AGENCY'],
    }),

    // Vehicle endpoints
    getVehicles: builder.query({
      query: ({ agencyId } = {}) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/vehicles`
          : `${ASSET_LIBRARY_API_URL}/vehicles/agency`,
        title: 'Vehicles',
        method: 'GET',
        params: IS_MIO_APP ? { agencyId } : { id: agencyId },
        transformResponse,
      }),
      providesTags: ['VEHICLES'],
    }),

    getVehicle: builder.query({
      query: ({ id }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/vehicles/${id}`
          : `${ASSET_LIBRARY_API_URL}/vehicle`,
        title: 'Vehicle',
        method: 'GET',
        params: { id },
        transformResponse,
      }),
      providesTags: ['VEHICLE'],
    }),

    editVehicle: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/vehicles/${data.id}`
          : `${ASSET_LIBRARY_API_URL}/vehicle`,
        title: 'Edit Vehicle',
        method: 'PUT',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: ['VEHICLE', 'VEHICLES'],
    }),

    createVehicle: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/vehicles`
          : `${ASSET_LIBRARY_API_URL}/vehicle`,
        title: 'Create Vehicle',
        method: 'POST',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: ['VEHICLE', 'VEHICLES'],
    }),

    deleteVehicle: builder.mutation({
      queryFn: async (vehicles, _, __, query) =>
        multiQuery(vehicles, { query }, ({ id }) => ({
          url: IS_MIO_APP
            ? `${ASSET_LIBRARY_API_URL}/vehicles/${id}`
            : `${ASSET_LIBRARY_API_URL}/vehicle`,
          title: 'Delete Vehicle',
          method: 'DELETE',
          data: { id },
          transformResponse,
        })),
      invalidatesTags: ['VEHICLES', 'VEHICLE', 'DEVICES', 'DEVICE'],
    }),

    // Device endpoints
    getDevices: builder.query({
      query: ({ agencyId } = {}) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/communicators`
          : `${ASSET_LIBRARY_API_URL}/communicators/agency`,
        title: 'Devices',
        method: 'GET',
        params: IS_MIO_APP ? { agencyId } : { id: agencyId },
        transformResponse,
      }),
      providesTags: ['DEVICES', 'AVAILABLE_DEVICES', 'ASSOCIATED_DEVICES'],
    }),

    getDevice: builder.query({
      query: ({ id }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/communicators/${id}`
          : `${ASSET_LIBRARY_API_URL}/communicator`,
        title: 'Device',
        method: 'GET',
        params: { id },
        transformResponse,
      }),
      providesTags: ['DEVICE'],
    }),

    editDevice: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/communicators/${data.id}`
          : `${ASSET_LIBRARY_API_URL}/communicator`,
        title: 'Edit Device',
        method: 'PUT',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: [
        'DEVICE',
        'DEVICES',
        'AVAILABLE_DEVICES',
        'ASSOCIATED_DEVICES',
      ],
    }),

    createDevice: builder.mutation({
      query: (data) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/communicators`
          : `${ASSET_LIBRARY_API_URL}/communicator`,
        title: 'Create Device',
        method: 'POST',
        data: IS_MIO_APP ? data : snakeize(data),
        transformResponse,
      }),
      invalidatesTags: [
        'DEVICE',
        'DEVICES',
        'AVAILABLE_DEVICES',
        'ASSOCIATED_DEVICES',
      ],
    }),

    deleteDevice: builder.mutation({
      queryFn: async (devices, _, __, query) =>
        multiQuery(devices, { query }, ({ id }) => ({
          url: IS_MIO_APP
            ? `${ASSET_LIBRARY_API_URL}/communicators/${id}`
            : `${ASSET_LIBRARY_API_URL}/communicator`,
          title: 'Delete Device',
          method: 'DELETE',
          data: { id },
          transformResponse,
        })),
      invalidatesTags: [
        'DEVICE',
        'DEVICES',
        'AVAILABLE_DEVICES',
        'ASSOCIATED_DEVICES',
      ],
    }),

    dissociateDevice: builder.mutation({
      query: ({ id, vehicleId, agencyId }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/communicators/${id}/dissociate/${vehicleId}`
          : `${ASSET_LIBRARY_API_URL}/dissociatecommunicator`,
        title: 'Dissociate Device from Vehicle',
        method: 'PUT',
        data: IS_MIO_APP
          ? { id, vehicleId, agencyId }
          : snakeize({ id, vehicleId, agencyId }),
        transformResponse,
      }),
      invalidatesTags: [
        'DEVICE',
        'DEVICES',
        'AVAILABLE_DEVICES',
        'ASSOCIATED_DEVICES',
      ],
    }),

    associateDevice: builder.mutation({
      query: ({ id, vehicleId, agencyId }) => ({
        url: IS_MIO_APP
          ? `${ASSET_LIBRARY_API_URL}/communicators/${id}/associate/${vehicleId}`
          : `${ASSET_LIBRARY_API_URL}/associatedcommunicator/communicator`,
        title: 'Associate Device to Vehicle',
        method: 'PUT',
        data: IS_MIO_APP
          ? { id, vehicleId, agencyId }
          : snakeize({ id, vehicleId, agencyId }),
        transformResponse,
      }),
      invalidatesTags: [
        'DEVICE',
        'DEVICES',
        'AVAILABLE_DEVICES',
        'ASSOCIATED_DEVICES',
      ],
    }),

    /**
     * Asset Library batch
     */
    uploadStaticIntersections: builder.mutation({
      query: ({ data, regionName, agencyName }) => ({
        url: `${INTERSECTIONS_URL}/import`,
        title: 'Static Intersections',
        method: 'POST',
        params: { regionName, agencyName },
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'multipart/form-data',
        },
        data,
      }),
    }),

    /**
     * Batch Endpoint
     */
    uploadBatch: builder.mutation({
      query: ({ data, email, owner }) => ({
        url: `${ASSET_LIBRARY_API_URL}/batch`,
        title: 'Batch',
        method: 'POST',
        params: { type: 'create', email, owner },
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'multipart/form-data',
        },
        data,
      }),
    }),

    getBatchStatus: builder.query({
      query: (id) => ({
        url: `${ASSET_LIBRARY_API_URL}/batch`,
        title: 'Batch',
        method: 'GET',
        params: { type: 'status', id },
      }),
      providesTags: ['BATCH_JOB'],
    }),

    refreshBatchStatus: builder.mutation({
      queryFn: () => ({}),
      invalidatesTags: ['BATCH_JOB'],
    }),

    /**
     * Whelen Change Preemption Endpoint
     */
    changePreemption: builder.mutation({
      queryFn: (args, _, __, query) =>
        preemptionQuery(args, { query }, ({ agencyId, devices }) => ({
          url: `${WHELEN_URL}/changepreemption`,
          title: 'Change Preemption',
          method: 'POST',
          data: {
            agency_guid: agencyId,
            devices,
          },
        })),
    }),

    getChangePreemptionStatus: builder.mutation({
      queryFn: async (args, _, __, query) =>
        preemptionStatusQueryWithRetry(args, { query }, (queueUrl) => ({
          url: `${WHELEN_URL}/changepreemption`,
          title: 'Change Preemption Status',
          method: 'GET',
          params: {
            queue_url: queueUrl,
            type: 'status',
          },
        })),
      invalidatesTags: ['DEVICES'],
    }),

    startWhelenImport: builder.mutation({
      query: ({ agencyId }) => ({
        url: `${WHELEN_URL}/importvehicles`,
        title: 'Refresh Whelen Import',
        method: 'POST',
        params: {
          agency_guid: agencyId,
        },
      }),
      transformResponse: (d) => ({ jobId: d?.QueueUrl }),
    }),

    getWhelenImportStatus: builder.query({
      query: ({ jobId }) => ({
        url: `${WHELEN_URL}/importvehicles`,
        title: 'Check Whelen Import Status',
        method: 'GET',
        params: {
          type: 'status',
          queue_url: jobId,
        },
      }),
      transformResponse: (d) => ({ isDone: d?.Done }),
    }),

    getOccupancyData: builder.query({
      queryFn: () =>
        mockQuery({
          title: 'Occupancy Data',
          query: getOccupancyData,
        }),
    }),
  }),
});

export const {
  useGetVPSSQuery,
  useEditVPSSMutation,
  useCreateVPSSMutation,
  useRefreshVPSSMutation,

  useGetIntersectionsQuery,
  useGetIntersectionQuery,
  useCreateIntersectionMutation,
  useUpdateIntersectionMutation,
  useRefreshIntersectionsMutation,
  useRefreshIntersectionMutation,

  useSetCMSMutation,
  useGetCSVMutation,

  useGetRegionsQuery,
  useGetRegionQuery,
  useEditRegionMutation,
  useCreateRegionMutation,
  useDeleteRegionMutation,

  useGetAgenciesQuery,
  useGetAgencyQuery,
  useEditAgencyMutation,
  useCreateAgencyMutation,
  useDeleteAgencyMutation,

  useGetVehiclesQuery,
  useGetVehicleQuery,
  useEditVehicleMutation,
  useCreateVehicleMutation,
  useDeleteVehicleMutation,

  useGetDevicesQuery,
  useGetDeviceQuery,
  useEditDeviceMutation,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useDissociateDeviceMutation,
  useAssociateDeviceMutation,

  // Batch
  useUploadBatchMutation,
  useGetBatchStatusQuery,
  useRefreshBatchStatusMutation,

  // Intersections
  useUploadStaticIntersectionsMutation,

  // Whelen
  useChangePreemptionMutation,
  useStartWhelenImportMutation,
  useGetWhelenImportStatusQuery,
  useGetOccupancyDataQuery,
  useGetChangePreemptionStatusMutation,
} = api;

export const { resetApiState } = api.util;

export default api;
