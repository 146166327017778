/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from '../api';
import { LocalStorageKeys } from '../enums';
import { CHANNEL_MAPPINGS } from '../constants';
import { accessLocalStorage } from '../../../common/utils';
import openNotification from '../../../common/components/notification';

const { storeLocalItem, _ } = accessLocalStorage();

const initialState = {
  /* Route */
  vpss: {},
  csv: {},
  regions: {},
  region: {},
  agencies: {},
  agency: {},
  vehicle: {},
  device: {},
  preemptionStatuses: {},
  intersection: {},
  intersectionGeneralData: {},
  intersectionApproachMap: [],
  intersectionThresholds: {},
  intersectionOutputs: {},
  intersectionChannels: [],
  intersectionChannelNames: [],
  intersectionNtcipConfiguration: {},
  intersectionSaved: false,
  intersections: [],
  approachMapEditor: {
    isCreating: false,
    /**
      @type {{
        approachName: string,
        approachNum: number,
        index: number
      }}
     */
    approach: null,
    selectedSegment: null,
  },
};

const filterOutdatedApproachValues = (approachMap, channels) => {
  // If the approachMap has ten approaches, we need to check if there is
  // more than one "10" value across all of the assignedApproaches arrays.
  // This is because of a previous issue with the assignedApproaches arrays
  // filling in "10" for the "unassigned" value, when it should be "0".
  // If there is more than one "10", we have to then check if the name of
  // the 10th approach matches the name of any of the channels. If it does,
  // then we can filter out the other "10" values from all assignedApproaches
  // arrays. Otherwise, throw a warning notification to tell the user to
  // update the channels that each approach is associated to.
  if (approachMap.length === 10) {
    const approachToCheck = approachMap[9];
    const { approachName } = approachToCheck;
    const matchingChannel = channels.find(
      (ch) => ch.channelName === approachName
    );
    if (matchingChannel) {
      const matchingChannelNum = matchingChannel.channel;
      const filteredChannels = channels.map((ch, __) => {
        if (ch.channel === matchingChannelNum) return ch;

        const { assignedApproaches } = ch;
        const filteredApproaches = assignedApproaches.map((apNum) =>
          apNum === 10 ? 0 : apNum
        );
        return { ...ch, assignedApproaches: filteredApproaches };
      });
      return filteredChannels;
    }
    openNotification({
      type: 'warning',
      message: 'Warning',
      description: `One of the approaches is not properly associated to its channel. Please re-selected the channel for each approach`,
    });
    return channels;
  }
  return channels.map((ch, __) => {
    const { assignedApproaches } = ch;
    const filteredApproaches = assignedApproaches.map((apNum) =>
      apNum === 10 ? 0 : apNum
    );
    return { ...ch, assignedApproaches: filteredApproaches };
  });
};

const configuration = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setVPSs: (state, { payload }) => {
      state.vpss = payload;
    },
    setCSV: (state, { payload }) => {
      state.csv = payload;
    },
    setAgency: (state, { payload }) => {
      state.agency = payload;
    },
    setVehicle: (state, { payload }) => {
      state.vehicle = payload;
    },
    setDevice: (state, { payload }) => {
      state.device = payload;
    },
    setIntersection: (state, { payload }) => {
      state.intersection = payload;
    },
    setIntersectionGeneralData: (state, { payload }) => {
      state.intersectionGeneralData = payload;
    },
    addApproach: (state, { payload: newApproach }) => {
      state.intersectionApproachMap.push(newApproach);
      state.approachMapEditor.isCreating = false;
    },
    updateIntersectionApproachMap: (state, { payload }) => {
      const { approachNum } = payload;
      const { approach } = payload;
      state.intersectionApproachMap[approachNum] = approach;
    },
    updateIntersectionApproachMapSegment: (state, { payload }) => {
      const { approachNum, index, segment } = payload;
      const { coordinates } = state.intersectionApproachMap[approachNum];
      state.intersectionApproachMap[approachNum].coordinates[index] = {
        ...coordinates[index],
        ...segment,
      };
    },
    setIntersectionThresholds: (state, { payload }) => {
      state.intersectionThresholds = payload;
    },
    setIntersectionOutputs: (state, { payload }) => {
      state.intersectionOutputs = payload;
    },
    setAssignedApproaches: (state, { payload }) => {
      const { channel } = payload;
      const approachNum =
        payload.approachNum ?? state.intersectionApproachMap.length - 1;
      const { intersectionChannels } = state;

      intersectionChannels.forEach((ch) => {
        const channelNum = ch.channel;

        // The approach needs to be added to the first unassigned
        // slot in the channel's assignedApproaches array
        if (channel === CHANNEL_MAPPINGS[channelNum]) {
          const assignedApproaches = [...ch.assignedApproaches];
          if (assignedApproaches.indexOf(approachNum + 1) !== -1) return;

          const index = assignedApproaches.indexOf(0);
          if (index === -1) assignedApproaches[0] = approachNum + 1;
          else assignedApproaches[index] = approachNum + 1;

          state.intersectionChannels[channelNum].assignedApproaches = [
            ...assignedApproaches,
          ];

          // Approach name needs to be the same as the channel name
          const { channelName } = ch;
          state.intersectionApproachMap[approachNum].approachName = channelName;
        }
        // The approach needs to also be removed from the assignedApproaches
        // array of any other channel it may have been assigned to
        else {
          const index = ch.assignedApproaches.indexOf(approachNum + 1);
          if (index === -1) return;

          const assignedApproaches = [...ch.assignedApproaches];
          assignedApproaches[index] = 0;
          state.intersectionChannels[channelNum].assignedApproaches = [
            ...assignedApproaches,
          ];
        }
      });
    },
    setIntersectionChannelNames: (state, { payload }) => {
      state.intersectionChannelNames = payload;
      // Approach names must always match channel names
      state.intersectionChannels = state.intersectionChannels.map((ch, i) => {
        const channelName = payload[i];
        const { assignedApproaches } = ch;
        assignedApproaches.forEach((approachNum) => {
          if (approachNum === 0) return;
          if (!state.intersectionApproachMap[approachNum - 1]) return;
          state.intersectionApproachMap[approachNum - 1].approachName =
            channelName;
        });

        return { ...ch, channelName };
      });

      const { lowPriority, highPriority } = state.intersectionThresholds;
      state.intersectionThresholds.lowPriority = lowPriority.map((ch, i) => ({
        ...ch,
        channelName: payload[i],
      }));
      state.intersectionThresholds.highPriority = highPriority.map((ch, i) => ({
        ...ch,
        channelName: payload[i],
      }));
    },
    setIntersectionNtcipConfiguration: (state, { payload }) => {
      state.intersectionNtcipConfiguration = payload;
    },
    setIntersectionSaved: (state, { payload }) => {
      state.intersectionSaved = payload;
    },
    setIntersections: (state, { payload }) => {
      state.intersections = payload;
    },
    setPreemptionStatuses: (state, { payload }) => {
      state.preemptionStatuses = { ...state.preemptionStatuses, payload };
    },
    removePreemptionStatus: (state, { payload }) => {
      delete state.preemptionStatuses[payload];
    },
    toggleEditingApproachMap: (state) => {
      state.approachMapEditor.isEditing = !state.approachMapEditor.isEditing;
    },
    startCreatingNewApproachMap: (state) => {
      state.approachMapEditor.isCreating = true;
    },
    stopCreatingNewApproachMap: (state) => {
      state.approachMapEditor.isCreating = false;
    },
    updateApproachMapEditor: (state, { payload }) => {
      const { approachMapEditor } = state;
      const { approach, selectedSegment } = payload;
      state.approachMapEditor.approach = {
        ...approachMapEditor.approach,
        ...approach,
      };
      state.approachMapEditor.selectedSegment = {
        ...approachMapEditor.selectedSegment,
        ...selectedSegment,
      };
    },
    updateSelectedSegment: (state, { payload }) => {
      const { selectedSegment } = state.approachMapEditor;
      state.approachMapEditor.selectedSegment = {
        ...selectedSegment,
        ...payload,
      };
      state.intersectionSaved = false;
    },
    clearSelectedSegment: (state) => {
      state.approachMapEditor.approach = null;
      state.approachMapEditor.selectedSegment = null;
    },
    deleteSelectedSegment: (state) => {
      if (
        !state.intersectionApproachMap ||
        !state.approachMapEditor.selectedSegment
      )
        return;

      const { approachNum, approachName } = state.approachMapEditor.approach;
      const { index } = state.approachMapEditor.selectedSegment;

      const matchingApproach = (state.intersectionApproachMap.filter(
        (_, i) => i === approachNum
      ) || [])[0];

      if (!matchingApproach || !matchingApproach?.coordinates?.length) return;

      // If an approach segment is deleted, all preceeding segments
      // are now detached, and therefore need to be deleted as well.
      const updatedCoordinates = matchingApproach.coordinates.filter(
        (_, i) => i > index
      );

      const updatedApproach = {
        approachName,
        numberOfCoordinates: updatedCoordinates.length,
        coordinates: updatedCoordinates,
      };

      if (updatedApproach.numberOfCoordinates < 2)
        state.intersectionApproachMap.splice(approachNum, 1);
      else state.intersectionApproachMap[approachNum] = updatedApproach;

      if (updatedApproach.numberOfCoordinates < 2) {
        // Each channel's assigned approaches needs to be updated after an
        // approach gets deleted, as the approaches are associated to a
        // channel based on their index in the list of approaches
        state.intersectionChannels = state.intersectionChannels.map((ch, _) => {
          const assignedApproaches = [...ch.assignedApproaches];
          assignedApproaches.forEach((apNum, i) => {
            // Only need to update the approaches that got shifted "down" in the array
            if (apNum > approachNum + 1) assignedApproaches[i] -= 1;
            if (apNum === approachNum + 1) assignedApproaches[i] = 0;
          });
          return { ...ch, assignedApproaches };
        });
      }
      state.approachMapEditor.approach = null;
      state.approachMapEditor.selectedSegment = null;
      state.intersectionSaved = false;
    },
  },
  extraReducers: (builder) => {
    // Save response from 'getRegions' api endpoint to store
    builder.addMatcher(
      api.endpoints.getRegions.matchFulfilled,
      (state, { payload }) => {
        payload.forEach((region) => {
          state.regions[region.id] = region;
        });
      }
    );

    // Save response from 'getRegion' api endpoint to store
    builder.addMatcher(
      api.endpoints.getRegion.matchFulfilled,
      (state, { payload }) => {
        state.region = payload;
      }
    );

    // Save response from 'getAgencies' api endpoint to store
    builder.addMatcher(
      api.endpoints.getAgencies.matchFulfilled,
      (state, { payload }) => {
        payload.forEach((agency) => {
          state.agencies[agency.id] = agency;
        });
      }
    );

    // Save response from 'getAgency' api endpoint to store
    builder.addMatcher(
      api.endpoints.getAgency.matchFulfilled,
      (state, { payload }) => {
        state.agency = payload;
        storeLocalItem(LocalStorageKeys.Agency, JSON.stringify(payload));
      }
    );

    // Save response from 'getIntersections' api endpoint to store
    builder.addMatcher(
      api.endpoints.getIntersections.matchFulfilled,
      (state, { payload }) => {
        state.intersections = payload;
      }
    );

    // Save response from 'getIntersection' api endpoint to store
    builder.addMatcher(
      api.endpoints.getIntersection.matchFulfilled,
      (state, { payload }) => {
        state.intersection = payload;
        state.intersectionGeneralData = (({
          intersectionName,
          locationType,
          serialNumber,
          latitude,
          longitude,
          make,
          model,
          timezone,
        }) => ({
          intersectionName,
          locationType,
          serialNumber,
          latitude,
          longitude,
          make,
          model,
          timezone,
        }))(payload);
        state.intersectionApproachMap = payload.approachMap || [];
        state.intersectionThresholds = payload.thresholds || [];
        state.intersectionOutputs = payload.outputs || [];
        state.intersectionNtcipConfiguration = payload.ntcipConfiguration || [];
        state.intersectionChannels =
          filterOutdatedApproachValues(payload.approachMap, payload.channels) ||
          [];
        state.intersectionChannelNames = payload.thresholds.lowPriority.map(
          (ch) => ch.channelName
        );
      }
    );

    // Save response from 'createIntersection' api endpoint to store
    builder.addMatcher(
      api.endpoints.createIntersection.matchFulfilled,
      (state, { payload }) => {
        state.intersection = payload;
      }
    );

    // Update progress of preemption status change
    builder.addMatcher(
      api.endpoints.changePreemption.matchPending,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { vehicleId },
            },
          },
        }
      ) => {
        state.preemptionStatuses[vehicleId] = 'Pending...';
      }
    );

    builder.addMatcher(
      api.endpoints.changePreemption.matchRejected,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { vehicleId },
            },
          },
        }
      ) => {
        delete state.preemptionStatuses[vehicleId];
      }
    );

    // Remove preemption status from store once change is complete
    builder.addMatcher(
      api.endpoints.getChangePreemptionStatus.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { vehicleId },
            },
          },
        }
      ) => {
        delete state.preemptionStatuses[vehicleId];
      }
    );

    builder.addMatcher(
      api.endpoints.getChangePreemptionStatus.matchRejected,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { vehicleId },
            },
          },
        }
      ) => {
        delete state.preemptionStatuses[vehicleId];
      }
    );
  },
});

export const {
  setVPSs,
  setCSV,
  setAgency,
  setVehicle,
  setDevice,
  setIntersections,
  setIntersectionGeneralData,
  addApproach,
  updateIntersectionApproachMap,
  updateIntersectionApproachMapSegment,
  setIntersectionThresholds,
  setIntersectionOutputs,
  setAssignedApproaches,
  setIntersectionChannelNames,
  setIntersectionNtcipConfiguration,
  setIntersectionSaved,
  setIntersection,
  setPreemptionStatuses,
  removePreemptionStatus,
  startCreatingNewApproachMap,
  stopCreatingNewApproachMap,
  toggleEditingApproachMap,
  updateApproachMapEditor,
  updateSelectedSegment,
  clearSelectedSegment,
  deleteSelectedSegment,
  updateApproachCoordinates,
} = configuration.actions;
export default configuration.reducer;

export const fetchVPSS = () => (dispatch) => {
  dispatch(setVPSs());
};
