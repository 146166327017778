import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { readableDateTimeFormatter } from '../../../../common/utils';
import Table, { alphanumericSorter } from '../../../../common/components/Table';
import TableCard from '../../../../common/components/TableCard';
import './style.css';
import MakeModel from '../../../../common/components/MakeModel';
import statusColorPicker from '../../../../common/utils/statusColorPicker';

const columns = [
  {
    dataIndex: 'intersectionName',
    key: 'intersectionName',
    title: 'Intersections',
    defaultSortOrder: 'ascend',
    sorter: alphanumericSorter('intersectionName'),
    render: (_, record) => (
      <h4
        style={{
          color: statusColorPicker(record.status),
        }}
      >
        {record.intersectionName}
      </h4>
    ),
  },
  {
    dataIndex: 'requestsToday',
    key: 'requestsToday',
    title: 'Requests Today',
    sorter: alphanumericSorter('requestsToday'),
  },
  {
    dataIndex: 'requestsLast30Days',
    key: 'requestsLast30Days',
    title: 'Requests Last 30 Days',
    sorter: alphanumericSorter('requestsLast30days'),
  },
  {
    dataIndex: 'lastHeartbeatHeard',
    key: 'lastHeartbeatHeard',
    title: 'Last Heard Heartbeat',
    subtitle: 'Last Heartbeat Heard',
    render: (_text, record) =>
      moment(record.lastHeartbeatHeard).format('MM/DD/YYYY hh:mm:ss A'),
    sorter: alphanumericSorter('lastHeartbeatHeard'),
  },
  {
    dataIndex: 'lastVehicleRequest',
    key: 'lastVehicleRequest',
    title: 'Last Vehicle Heard',
    render: (_text, record) =>
      moment(record.dateLastLogRetrieved).format('MM/DD/YYYY hh:mm:ss A'),
    sorter: alphanumericSorter('lastVehicleRequest'),
  },
  {
    dataIndex: 'numErrors',
    key: 'numErrors',
    title: 'Errors In Last 30 Days',
    sorter: alphanumericSorter('numErrors'),
  },
  {
    dataIndex: 'firmwareVersion',
    key: 'firmwareVersion',
    title: 'Firmware Version',
    sorter: alphanumericSorter('firmwareVersion'),
  },
  {
    key: 'makeModel',
    dataIndex: 'makeModel',
    title: 'Device',
    render: (_, { makeModel }) => <MakeModel makeModel={makeModel} />,
    sorter: alphanumericSorter('makeModel'),
    sortDirections: ['ascend', 'descend'],
  },
  {
    key: 'ipAddress',
    dataIndex: 'ipAddress',
    title: 'IP & Port',
    render: (_, { ipAddress, port }) => `${ipAddress} : ${port}`,
    sorter: alphanumericSorter('ipAddress'),
    sortDirections: ['ascend', 'descend'],
  },
];

const IntersectionsList = ({ intersections, isLoading }) => (
  <TableCard
    table={
      <Table
        className="intersections-list__table"
        pagination={true}
        isLoading={isLoading}
        columns={columns}
        rowKey={(value) => value.intersectionName}
        dataSource={intersections}
        expandable={{
          expandedRowRender: (value) => <p>SN: {value.serialNumber}</p>,
        }}
      />
    }
  ></TableCard>
);

export default withRouter(IntersectionsList);
