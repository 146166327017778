import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Form, Col, Row, Select } from 'antd';
import Button from '../../../../common/components/Button';
import { CHANNEL_TYPES } from '../../constants';
import { mapDataToInitialValues } from '../../utils';
import {
  setIntersectionOutputs,
  setIntersectionSaved,
} from '../../store/slice';
import './style.css';

const { TabPane } = Tabs;

const OUTPUT_TYPES = [
  'NTCIP 1211',
  'NTCIP 1202 (be advised, this option is a deviation from the standards)',
];

const OutputsForm = ({
  formData,
  channelNames,
  priority,
  onFormDataChange,
  resetToDefaults,
}) => {
  const [channelsData, setChannelsData] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const { type, channels } = formData || { channels: [] };
    setChannelsData(channels);

    const initialValues = mapDataToInitialValues(channels);
    initialValues.type = type || 'NTCIP 1211';

    form.setFieldsValue(initialValues);
  }, [form, formData]);

  const outputOptions = useMemo(() => {
    const numOutputs = priority === 'lowPriority' ? 6 : 225;

    return Array.from({ length: numOutputs }, (_, i) => (
      <Select.Option value={i + 1} key={i + 1}>
        {i + 1}
      </Select.Option>
    ));
  }, [priority]);

  const handleFormDataChange = useCallback(() => {
    const newOutputs = { type: '', channels: [] };
    const formValues = form.getFieldsValue();

    Object.entries(formValues).forEach((entry) => {
      const [key, value] = entry;
      if (key === 'type') newOutputs[key] = formValues[key].slice(0, 10);
      else {
        const index = key.slice(0, 1);
        const output = key.slice(2);

        if (!(index in newOutputs.channels)) {
          newOutputs.channels[index] = { channel: index };
        }

        newOutputs.channels[index][output] = value;
      }
    });

    onFormDataChange(newOutputs);
  }, [form, onFormDataChange]);

  return (
    <Form
      form={form}
      className="outputs-form"
      layout="vertical"
      onFieldsChange={(_, allFields) => handleFormDataChange(allFields)}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="type"
            label={
              <p>
                <b>
                  {priority === 'lowPriority' ? 'Low' : 'High'} Priority Output
                  Type
                </b>{' '}
                (applies to all channels)
              </p>
            }
          >
            <Select>
              {OUTPUT_TYPES.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {channelsData.map((_, index) => (
          <Col key={index} span={6}>
            <p className="outputs-form-channel-title">
              Channel {CHANNEL_TYPES[index]}
              {` `}
              {channelNames[index] !== '' && `(${channelNames[index]})`}
            </p>
          </Col>
        ))}
      </Row>
      <Row>
        {channelsData.map((_, index) => (
          <Col className="outputs-form-channels" key={index} span={6}>
            <Form.Item name={`${index}-straight`} label="Straight">
              <Select>{outputOptions}</Select>
            </Form.Item>
            <Form.Item name={`${index}-left`} label="Left">
              <Select>{outputOptions}</Select>
            </Form.Item>
            <Form.Item name={`${index}-right`} label="Right">
              <Select>{outputOptions}</Select>
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={6}>
          <Button
            className="outputs-button"
            type="danger"
            size="lg"
            onClick={() => resetToDefaults()}
          >
            Reset to Default
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ApproachesOutputs = ({ outputs, channelNames, resetToDefaults }) => {
  const { lowPriority, highPriority } = outputs;
  const dispatch = useDispatch();

  return (
    <Tabs className="outputs-tabs" tabBarStyle={{ backgroundColor: '#f1efef' }}>
      <TabPane tab="Low Priority" key="lowPriorityOutputs">
        <OutputsForm
          formData={lowPriority}
          channelNames={channelNames}
          onFormDataChange={(fd) => {
            dispatch(setIntersectionOutputs({ ...outputs, lowPriority: fd }));
            dispatch(setIntersectionSaved(false));
          }}
          priority="lowPriority"
          resetToDefaults={resetToDefaults}
        />
      </TabPane>
      <TabPane tab="High Priority" key="highPriorityOutputs">
        <OutputsForm
          formData={highPriority}
          channelNames={channelNames}
          onFormDataChange={(fd) => {
            dispatch(setIntersectionOutputs({ ...outputs, highPriority: fd }));
            dispatch(setIntersectionSaved(false));
          }}
          priority="highPriority"
          resetToDefaults={resetToDefaults}
        />
      </TabPane>
    </Tabs>
  );
};

export default ApproachesOutputs;
