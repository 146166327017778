import React, { useState, useRef, useCallback } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import mapStyles from './mapStyles';
import Legend from '../IntersectionsMapSettings/Legend';
import './style.css';
import Skeleton from '../../../../common/components/Skeleton';
import statusColorPicker from '../../../../common/utils/statusColorPicker';
import { fitGMap } from '../../../../common/components/Map/GMaps/utils';

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoomControlOptions: {
    position: 6,
  },
};

const SearchCard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Draggable>
      <div className="complete-modal">
        <div className="modal-collapse">
          {isCollapsed ? (
            <PlusOutlined
              className="collapse-button"
              onClick={() => setIsCollapsed(false)}
            />
          ) : (
            <MinusOutlined
              className="collapse-button"
              onClick={() => setIsCollapsed(true)}
            />
          )}
        </div>

        <div className="legend_only">
          <h2 className="all-intersections">All Intersections</h2>
          {!isCollapsed && (
            <div className="map-settings__legend">
              <Legend />
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

const InfoCard = ({ selected }) => {
  const lastHbHeardSec = (
    (Date.now() - Date.parse(selected.lastHeartbeatHeard)) /
    1000
  ).toFixed(0);
  const lastVehicleHeard = (
    (Date.now() - Date.parse(selected.lastVehicleRequest)) /
    1000
  ).toFixed(0);

  return (
    <div className="title">
      <p
        className="title__heading"
        style={{ color: statusColorPicker(selected.status) }}
      >
        {selected.intersectionName}
      </p>
      <p className="last__heard">last heard {lastHbHeardSec} sec ago</p>
      <hr></hr>
      <p>
        <b>{lastVehicleHeard} seconds</b> since last vehicle heard
      </p>
      <p> {selected.numErrors} errors in last 30 days</p>
    </div>
  );
};

// TODO: automated the center calculation
export default function IntersectionsMap({
  intersections,
  selectedIntersections,
  isLoading,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
  });
  const [selected, setSelected] = useState(null);

  const mapContainer = useRef();

  const markers =
    selectedIntersections.length === 0
      ? intersections
      : intersections.filter((intersection) =>
          selectedIntersections.includes(intersection.locationname)
        );

  const onMapLoad = useCallback(
    (map) => {
      const width = mapContainer?.current?.offsetWidth;
      const height = mapContainer?.current?.offsetHeight;
      const transformedData = (markers ?? []).map(
        ({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude,
        })
      );
      fitGMap(transformedData, map, { width, height }, true);
    },
    [markers]
  );

  if (loadError) return 'Error';
  if (!isLoaded) return 'Loading...';

  return (
    <div className="intersections-map-container">
      {!isLoading ? (
        <GoogleMap id="map" options={options} onLoad={onMapLoad}>
          {markers !== []
            ? (markers ?? []).map((marker) => {
                const fillColor = statusColorPicker(marker.status);
                return (
                  <Marker
                    key={`${marker.latitude}-${marker.longitude}`}
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                    onClick={() => {
                      setSelected(marker);
                    }}
                    icon={{
                      path: 'M10,3c-3.866,0-7,3.133-7,7c0,3.865,3.134,7,7,7s7-3.135,7-7C17,6.133,13.866,3,10,3z',
                      fillColor,
                      fillOpacity: 1,
                      strokeWeight: 0,
                      scale: 0.6,
                    }}
                  />
                );
              })
            : null}
          {selected && (
            <InfoWindow
              position={{ lat: selected.latitude, lng: selected.longitude }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <div style={{ width: 'max-content' }}>
                <InfoCard selected={selected} />
              </div>
            </InfoWindow>
          )}
          <SearchCard />
        </GoogleMap>
      ) : (
        <Skeleton number={1} className="skeleton-map" active={isLoading} />
      )}
    </div>
  );
}
