import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUserSuccess, authUserFailure } from '../../store/slice';
import { useGetCurrentUserQuery } from '../../api';
import { UserType } from '../../../../common/enums';

const REFRESH_INTERVAL = 1000 * 60 * 5; // 5 minutes

const MioUserType = {
  ViewOnly: 'VIEW_ONLY',
  ReadWrite: 'READ_WRITE',
  OrgAdmin: 'ORG_ADMIN',
};
const MIO_USER_TYPES = Object.values(MioUserType);

const USER_TYPE_MAP = {
  [MioUserType.ViewOnly]: UserType.AgencyViewer,
  [MioUserType.ReadWrite]: UserType.AgencyAdmin,
  [MioUserType.OrgAdmin]: UserType.AgencyAdmin,
};

const mapRolesToUserType = (roles) => {
  const bestRole = roles.reduce((currBestRole, role) => {
    if (MIO_USER_TYPES.indexOf(role) <= MIO_USER_TYPES.indexOf(currBestRole)) {
      return currBestRole;
    }
    return role;
  }, undefined);

  return USER_TYPE_MAP[bestRole];
};

const MioAuthListener = ({ children }) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useGetCurrentUserQuery({
    refetchInterval: REFRESH_INTERVAL,
  });

  useEffect(() => {
    if (isLoading) return;
    if (isError) {
      dispatch(authUserFailure('getCurrentUser'));
    }

    const {
      currentOrganization,
      orgRoleMappings,
      mioAdmin: admin,
      externalId: username,
    } = data ?? {};

    const agencyId = currentOrganization?.id;
    const userType = admin
      ? UserType.PowerUser
      : mapRolesToUserType(orgRoleMappings?.[agencyId] || []);

    const user = {
      username,
      userType,
      agencyId,
      agency: currentOrganization?.name,
      admin,
    };

    dispatch(loginUserSuccess(user));
  }, [dispatch, data, isError, isLoading]);

  return children;
};

export default MioAuthListener;
